import { node } from 'prop-types';
import React, { Component } from 'react';
import En from '../copy/en-gb.json';

export const CopyContext = React.createContext();

class CopyProvider extends Component {
  static propTypes = {
    children: node.isRequired,
  };

  state = En;

  render() {
    const { children } = this.props;
    return (
      <CopyContext.Provider value={this.state}>{children}</CopyContext.Provider>
    );
  }
}
export default CopyProvider;
