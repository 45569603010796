/* eslint no-undef: 0*/

import Classnames from 'classnames';
import { string } from 'prop-types';
import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import PageLayout from '../../components/page-layout';
import Config from '../../config';
import {
  QUESTION_AGE,
  QUESTION_MONTHLY_CONTRIBUTION,
  QUESTION_RETIREMENT_INCOME,
  QUESTON_PENSION_POT,
} from '../../constants/fields';
import { AppContext } from '../../context/app';
import { CopyContext } from '../../context/copy';
import { HistoryPropertyTypes } from '../../prop-types';
import { ROUTE_HOME, ROUTE_RESULTS } from '../../routes';
import { formatNumber } from '../../utils';

import ScottishWidow_Desktop from './assets/scottish_widow_desktop.png';
import ScottishWidow_Mobile from './assets/scottish_widow_mobile.png';
import ScottishWidow_Tablet from './assets/scottish_widows_tablet.png';
import styles from './index.module.css';

const SubCopy = ({ copy, className = null }) => (
  <div
    className={`response sub-copy ${className ? className : ''}`}
    dangerouslySetInnerHTML={{ __html: copy }}
  />
);

SubCopy.propTypes = {
  copy: string.isRequired,
  className: string,
};

const PlanYourFuture = ({ history }) => (
  <AppContext.Consumer>
    {({ questions, results, isRVtablet }) => {
      if (!results.hasOwnProperty('current_retirement_prediction')) {
        history.push(ROUTE_HOME);
      }

      const timer = window.setInterval(() => {
        if (window.utag != undefined && window.utag.view != undefined) {
          window.clearInterval(timer);
          window.utag.view({
            CanonicalPath: window.location.pathname,
            CustomerAge: questions[QUESTION_AGE],
            JourneyAmount: questions[QUESTON_PENSION_POT],
            JourneyRate: questions[QUESTION_MONTHLY_CONTRIBUTION],
          });
        }
      }, 100);

      const { current_retirement_prediction } = results;

      return (
        <CopyContext.Consumer>
          {({
            planYourFuture: {
              title,
              cta_1_link,
              cta_1,
              cta_2,
              cta_2_link,
              cta_2_mobile,
              cta_2_link_mobile,
              copy_a,
              copy_b,
              copy_c,
              caveat,
              RVTablet,
            },
          }) => {
            const formatCopy = copy =>
              copy
                .replace('RETIREMENT_AGE', current_retirement_prediction)
                .replace(
                  'ANNUAL_INCOME',
                  formatNumber(questions[QUESTION_RETIREMENT_INCOME])
                )
                .replace(
                  'CONTRIBUTION_PER_MONTH',
                  formatNumber(questions[QUESTION_MONTHLY_CONTRIBUTION])
                );

            const copy = () => {
              if (isRVtablet) {
                return (
                  <SubCopy
                    copy={formatCopy(RVTablet)}
                    className={styles.subcopyRTablet}
                  />
                );
              }

              if (
                questions[QUESTION_MONTHLY_CONTRIBUTION] == 0 &&
                questions[QUESTON_PENSION_POT] == 0
              ) {
                return <SubCopy copy={copy_a} />;
              }

              if (current_retirement_prediction == 'Over 100') {
                return <SubCopy copy={copy_b} />;
              }

              return <SubCopy copy={formatCopy(copy_c)} />;
            };

            return (
              <>
                <Helmet>
                  <title>{`${Config.title} | Plan Your Future`}</title>
                </Helmet>
                <PageLayout backLink={ROUTE_RESULTS}>
                  <div
                    id="plan-your-future"
                    className={Classnames(styles.container, 'center-inner')}
                  >
                    <div className={styles.inner}>
                      <h1
                        className={`${styles.title} ${isRVtablet &&
                          styles.titleRVTablet} `}
                      >
                        {title}
                      </h1>
                      {copy()}
                    </div>
                    {!isRVtablet && (
                      <div className={styles.buttonGroup}>
                        <a
                          href={cta_1_link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="button button-primary center cta_1"
                          title={cta_1}
                        >
                          {cta_1}
                        </a>
                        <div className={styles['cta-container']}>
                          <a
                            href={cta_2_link_mobile}
                            rel="noopener noreferrer"
                            className={Classnames(
                              'button',
                              'button-primary',
                              'center',
                              styles['cta_2']
                            )}
                            title={cta_2_mobile}
                          >
                            {cta_2_mobile}
                          </a>
                          <a
                            href={cta_2_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={Classnames(
                              'button',
                              'button-primary',
                              'center',
                              styles['cta_2']
                            )}
                            title={cta_2}
                          >
                            {cta_2}
                          </a>
                        </div>
                        <div
                          className={Classnames(
                            styles.caveat,
                            'caveat',
                            'sub-copy'
                          )}
                          dangerouslySetInnerHTML={{
                            __html: caveat,
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <picture>
                    <source
                      media="(max-width: 1024px)"
                      srcSet={ScottishWidow_Tablet}
                    />
                    <source
                      media="(max-width: 500px)"
                      srcSet={ScottishWidow_Mobile}
                    />
                    <img
                      src={ScottishWidow_Desktop}
                      alt="Scottish widow"
                      className={styles.img}
                      draggable="false"
                    />
                  </picture>
                </PageLayout>
              </>
            );
          }}
        </CopyContext.Consumer>
      );
    }}
  </AppContext.Consumer>
);

PlanYourFuture.propTypes = {
  history: HistoryPropertyTypes,
};

export default memo(PlanYourFuture);
