import Classnames from 'classnames';
import { string, number } from 'prop-types';
import React from 'react';
import QuestionAnimation from '../question-animation';
import styles from './index.module.css';
import QuestionNumber from './question-number';

const QuestionHeader = ({ question, title, className, progress }) => (
  <div>
    <QuestionNumber question={question} />
    <div className={className} dangerouslySetInnerHTML={{ __html: title }} />
    <div className={Classnames(styles.img, 'img-container')}>
      <QuestionAnimation question={question} progress={progress} />
    </div>
  </div>
);

QuestionHeader.propTypes = {
  question: number.isRequired,
  title: string.isRequired,
  className: string,
  progress: number.isRequired,
};

QuestionHeader.defaultProps = {
  className: '',
};

export default QuestionHeader;
