import { node } from 'prop-types';
import React from 'react';
import Copy from './copy';
import styles from './index.module.css';
import InfoPanel from './info-panel';
import ControlPanelLink from './link';
import Sliders from './sliders';
import { SLIDERS_DESKTOP } from './sliders/constant';

const ControlPanel = ({backButton}) => {
  return (
    <>
      <div className={styles.mobile}>
        <div className="flex flex-column">
          <InfoPanel />
        </div>
      </div>
      <div className={styles.desktop}>
        <div className={styles.wrapper}>
          <div className={styles.copyWrapper}>
            <Copy />
          </div>
          <div className={styles.sliderWrapper}>
            <Sliders type={SLIDERS_DESKTOP} sidePanel />
          </div>
          <nav>
            <ul className={styles.ctaWrapper}>
              {backButton}
              <li className={styles.confirmButtonWrapper}>
                <ControlPanelLink />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

ControlPanel.propTypes = {
  backButton: node,
};

export default ControlPanel;
