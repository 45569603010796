import { func, string } from 'prop-types';
import React from 'react';
import Icon from '../../../components/icon';
import { ICON_CAMERA, ICON_DOWNLOAD } from '../../../components/icon/constant';
import { MODES } from '../../../context/facefilter';

import styles from './index.module.css';

const SnapshotButton = ({ onClick, mode }) => (
  <button
    className={styles.button}
    aria-label="Take a snapshot"
    onClick={onClick}
  >
    {mode == MODES.IMAGE ? (
      <Icon type={ICON_DOWNLOAD} />
    ) : (
      <Icon type={ICON_CAMERA} />
    )}
  </button>
);

SnapshotButton.propTypes = {
  onClick: func.isRequired,
  mode: string,
};

SnapshotButton.defaultProps = {
  mode: MODES.VIDEO,
};

export default SnapshotButton;
