import Classnames from 'classnames';
import { bool, string } from 'prop-types';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, withRouter } from 'react-router-dom';
import CONFIG from '../../config';
import { AppContext } from '../../context/app';
import { HistoryPropertyTypes, LocationPropertyTypes } from '../../prop-types';
import { ROUTE_HOME, ROUTE_ABOUT } from '../../routes';
import { isMobile } from '../../utils';
import Icon from '../icon';
import {
  ICON_INFO,
  ICON_COLOR_WHITE,
  ICON_SOCIAL,
  ICON_CLOSE,
  ICON_RESET,
  ICON_COPY_TO_CLIPBOARD,
} from '../icon/constant';
import Image from '../image';
import { RATIO_LOGO } from '../image/constants';
import BackButton from '../ui/back-button';
import LogoAlternative from './assets/logo-alternative.png';
import Logo from './assets/logo.png';

import CopyLinkSuccess from "./copy-link-success";
import styles from './index.module.css';
import Share from './share';

const Header = ({
  alternative,
  hideSocial,
  hideAbout,
  hideLogo,
  hideCopyToClipboard,
  location,
  history,
  backLink,
}) => {
  const color = alternative ? ICON_COLOR_WHITE : null;
  // The fallback is the known live url.
  const textToClipboard =
    typeof window !== 'undefined' ? window.location.host : CONFIG.siteUrl;

  const [copyLink, updateCopyLink] = useState(false);

  return (
    <>
      <header className={Classnames(styles.root)}>
        <div className={Classnames('container', styles.container)}>
          <div className={Classnames(styles.wrapper, 'flex', 'flex-v-center')}>
            {backLink && (
              <div className={styles.backButtonWrapper}>
                <BackButton backLink={backLink} />
              </div>
            )}
            {!hideLogo && (
              <div className={styles.logoWrapper}>
                <Link title="Scottish Widows" to={ROUTE_HOME}>
                  <Image
                    src={alternative ? LogoAlternative : Logo}
                    lazyLoad={false}
                    ratio={RATIO_LOGO}
                    id="logo"
                    altText="Scottish Widows"
                  />
                </Link>
              </div>
            )}
            <div className={styles.rightButtonsWrapper}>
              {/* Reset button */}
              <AppContext.Consumer>
                {appContext => {
                  if (!appContext) {
                    return false;
                  }

                  const { isRVtablet, resetState } = appContext;
                  return (
                    isRVtablet &&
                    location.pathname !== ROUTE_HOME && (
                      <button
                        className={`${styles.rightButton}`}
                        aria-label="Reset"
                        onClick={() => {
                          // reset
                          resetState();

                          // back to homepage
                          history.push(ROUTE_HOME);
                        }}
                      >
                        <Icon type={ICON_RESET} color={color} />
                      </button>
                    )
                  );
                }}
              </AppContext.Consumer>

              {/* Social button */}
              {!hideSocial && (
                <Share className={styles.rightButton}>
                  <Icon type={ICON_SOCIAL} color={color} />
                </Share>
              )}

              {/* Copy to clipboard button */}
              <AppContext.Consumer>
                {appContext => {
                  if (!appContext) {
                    return false;
                  }

                  const { isRVtablet } = appContext;

                  return (
                    !isRVtablet &&
                    !isMobile() &&
                    !hideCopyToClipboard && (
                      <CopyToClipboard text={textToClipboard}>
                        <button
                          className={`${styles.copy} ${styles.rightButton}`}
                          aria-label="Copy the URL to your clipboard"
                          onClick={() => updateCopyLink(true)}
                        >
                          <Icon type={ICON_COPY_TO_CLIPBOARD} color={color} />
                        </button>
                      </CopyToClipboard>
                    )
                  );
                }}
              </AppContext.Consumer>

              {/* Info button */}
              {!hideAbout && (
                <>
                  {history.location.pathname.replace(/\/$/, '') !==
                    ROUTE_ABOUT ? (
                      <Link
                        title="About"
                        to={ROUTE_ABOUT}
                        id="about-link"
                        className={`${styles.rightButton}`}
                      >
                        <Icon type={ICON_INFO} color={color} />
                      </Link>
                    ) : (
                      <button
                        id="about-close-button"
                        className={`${styles.rightButton}`}
                        type="button"
                        aria-label="Close"
                        onClick={() => {
                          if (location.hasOwnProperty('key')) {
                            history.goBack();
                          } else {
                            history.push('/');
                          }
                        }}
                      >
                        <Icon type={ICON_CLOSE} color={ICON_COLOR_WHITE} />
                      </button>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </header>
      <CopyLinkSuccess state={copyLink} callback={updateCopyLink} />
    </>
  );
};

Header.propTypes = {
  alternative: bool.isRequired,
  hideSocial: bool,
  hideAbout: bool,
  hideLogo: bool,
  hideCopyToClipboard: bool,
  history: HistoryPropertyTypes,
  location: LocationPropertyTypes,
  backLink: string,
};

Header.defaultProps = {
  hideSocial: false,
  hideAbout: false,
  hideLogo: false,
  hideCopyToClipboard: false,
  backLink: '',
};

export default withRouter(Header);
