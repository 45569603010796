import Classnames from 'classnames';
import React from 'react';
import styles from './index.module.css';

const Spinner = () => (
  <div className={Classnames(styles.root, 'spinner')}>
    <div className={styles.shadow} />
    <div className={styles.background} />
  </div>
);

export default Spinner;
