/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import Classnames from 'classnames';
import { node, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Config from '../../../config';
import { isMobile } from '../../../utils';
import styles from './index.module.css';

const Share = ({ children, className }) => {
  const [state, updateState] = useState(false);

  useEffect(() => {
    if (isMobile() && navigator.share) {
      updateState(true);
    }
  }, []);

  const onClick = async () => {
    try {
      await navigator.share({ title: Config.title, url: Config.siteUrl });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      {state ? (
        <button
          className={`${Classnames('social-button', styles.root)} ${className}`}
          type="button"
          onClick={onClick}
        >
          {children}
        </button>
      ) : null}
    </>
  );
};

Share.propTypes = {
  children: node.isRequired,
  className: string,
};

Share.defaultProps = {
  className: '',
};

export default Share;
