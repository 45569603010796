import React, { memo, useState, useEffect, useLayoutEffect } from 'react';
import Icon from '../../../../components/icon';
import {
  ICON_BACK_ARROW,
  ICON_COLOR_WHITE,
} from '../../../../components/icon/constant';
import BackButton from '../../../../components/ui/back-button';
import { ROUTE_QUESTIONS_SUMMARY } from '../../../../routes';

import Copy from '../copy';
import ControlPanelLink from '../link';
import Sliders from '../sliders';
import styles from './index.module.css';

const MOBILE_WIDTH = 1025;

const InfoPanel = () => {
  const controlPanelRef = React.createRef();
  const controlPanelContentRef = React.createRef();

  const [controlPanel, updateControlPanel] = useState(false);
  const [maxHeight, updateMaxHeight] = useState(0);
  const [contentMaxHeight, setContentMaxHeight] = useState(window.innerWidth);

  // only when controlPanel is updated
  useLayoutEffect(() => {
    toggleMaxHeight();
  }, [controlPanel]);

  // On every render
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  // on first render
  useEffect(() => {
    if (window.outerWidth < MOBILE_WIDTH) {
      handleResize();
    }
  }, []);

  const handleResize = () => {
    if (!controlPanelContentRef.current) return;
    const maxHeight = controlPanelContentRef.current.getBoundingClientRect()
      .height;
    setContentMaxHeight(maxHeight);
  };

  const toggleMaxHeight = () => {
    // set maxHeight based on open state
    const maxHeight = controlPanel ? contentMaxHeight : 0;
    updateMaxHeight(maxHeight);
  };

  return (
    <div className={styles.root} data-expanded={controlPanel}>
      <div className="container">
        <button
          className={styles.button}
          onClick={() => updateControlPanel(!controlPanel)}
          aria-controls="slider-panel"
          aria-expanded={controlPanel}
          id="slider-panel-control"
        >
          <Icon type={ICON_BACK_ARROW} color={ICON_COLOR_WHITE} />
          
        </button>

        <Copy />

        <div
          className={`${styles.sliderContainer} ${
            controlPanel ? styles.sliderContainerOpened : ''
          }`}
          ref={controlPanelRef}
          aria-hidden={!controlPanel}
          id="slider-panel"
          style={{ maxHeight }}
        >
          <div
            ref={controlPanelContentRef}
            className={`${styles.sliderContainerContent}`}
          >
            <Sliders />
          </div>
        </div>

        <nav className={styles.nav}>
          <ul className={styles.ctaWrapper}>
            <li className={styles.backButtonWrapper}>
              <BackButton backLink={ROUTE_QUESTIONS_SUMMARY} />
            </li>
            <li className={styles.confirmButtonWrapper}>
              <ControlPanelLink />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

// InfoPanel.propTypes = {
//   onClick: func.isRequired,
//   controlPanel: bool,
// };

export default memo(InfoPanel);
