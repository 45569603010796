import { string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.css';

const ConfirmButton = ({ toLink, ctaCopy }) => (
  <div className={styles.root}>
    <Link
      to={toLink}
      title="Next question"
      id="submit"
      className="submit button button-primary main-cta"
    >
      {ctaCopy}
    </Link>
  </div>
);

ConfirmButton.propTypes = {
  toLink: string.isRequired,
  ctaCopy: string.isRequired,
};

export default ConfirmButton;
