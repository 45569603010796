import Classnames from 'classnames';
import { node, oneOf, bool, string } from 'prop-types';
import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { BACKGROUND_WHITE, BACKGROUND_GRAIDENT, BACKGROUND_GRAPHICS } from './constants';
import styles from './index.module.css';

const PageLayout = ({
  children,
  background,
  hideSocial,
  hideAbout,
  backLink,
  hideLogo,
  hideCopyToClipboard,
  classNames,
}) => (
  <>
    <Header
      alternative={background === BACKGROUND_GRAIDENT || background === BACKGROUND_GRAPHICS}
      hideSocial={hideSocial}
      hideAbout={hideAbout}
      backLink={backLink}
      hideLogo={hideLogo}
      hideCopyToClipboard={hideCopyToClipboard}
    />
    <section
      className={`${Classnames(styles.root, styles[background])} ${
        classNames ? classNames : ''
      }`}
    >
      <div className={styles.inner}>{children}</div>
    </section>
    <Footer />
  </>
);

PageLayout.propTypes = {
  children: node.isRequired,
  background: oneOf([BACKGROUND_WHITE, BACKGROUND_GRAIDENT, BACKGROUND_GRAPHICS]),
  hideSocial: bool,
  hideAbout: bool,
  hideLogo: bool,
  hideCopyToClipboard: bool,
  backLink: string,
  classNames: string,
};

PageLayout.defaultProps = {
  background: BACKGROUND_GRAIDENT,
  hideSocial: false,
  hideAbout: false,
  hideLogo: false,
  hideCopyToClipboard: false,
  backLink: '',
};

export default PageLayout;
