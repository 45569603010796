import Classnames from 'classnames';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PageLayout from '../../components/page-layout';
import { BACKGROUND_GRAPHICS } from '../../components/page-layout/constants';
import { CopyContext } from '../../context/copy';
import { ROUTE_QUESTIONS_ONE } from '../../routes';
import FaceDesktop from './assets/face-desktop.png';
import FaceDesktopWebP from './assets/face-desktop.webp';
import FaceMobile from './assets/face-mobile.png';
import FaceMobileWebP from './assets/face-mobile.webp';
import FaceTablet from './assets/face-tablet.png';
import FaceTabletWebP from './assets/face-tablet.webp';
import styles from './index.module.css';

const HomePage = () => (
  <CopyContext.Consumer>
    {({ home }) => (
      <>
        <PageLayout
          background={BACKGROUND_GRAPHICS}
          classNames={styles.pageLayout}
        >
          <div className={Classnames('center-inner', styles.overlay)}>
            <h1 id="home-title" className={styles.title}>
              {home.title}
            </h1>
            {home.subTitle && home.subTitle !== 'EMPTY' && (
              <span
                id="home-sub-title"
                className={Classnames(styles.hashtag, 'red-font')}
              >
                {home.subTitle}
              </span>
            )}

            <div
              id="home-copy"
              className={styles.copy}
              dangerouslySetInnerHTML={{ __html: home.copy }}
            />
            <Link
              id="take-on-your-future"
              to={ROUTE_QUESTIONS_ONE}
              title={home.cta}
              className={`${styles.link} button button-primary center`}
            >
              {home.cta}
            </Link>
          </div>
          <picture>
            <source
              media="(max-width: 1024px)"
              srcSet={FaceTabletWebP}
              type="image/webp"
            />
            <source
              media="(max-width: 1024px)"
              srcSet={FaceTablet}
              type="image/jpeg"
            />
            <source
              media="(max-width: 500px)"
              srcSet={FaceMobileWebP}
              type="image/webp"
            />
            <source
              media="(max-width: 500px)"
              srcSet={FaceMobile}
              type="image/jpeg"
            />
            <source srcSet={FaceDesktopWebP} type="image/webp" />
            <source srcSet={FaceDesktop} type="image/jpeg" />
            <img
              src={FaceDesktop}
              alt="Scottish widow"
              className={styles.img}
              draggable="false"
            />
          </picture>
        </PageLayout>
      </>
    )}
  </CopyContext.Consumer>
);

export default memo(HomePage);
