import Classnames from 'classnames';
import { string, bool } from 'prop-types';
import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import PageLayout from '../../components/page-layout';
import { BACKGROUND_WHITE } from '../../components/page-layout/constants';
import Icon from '../icon';
import { ICON_ERROR } from '../icon/constant';
import styles from './index.module.css';

const ErrorPage = ({ pageTitle, title, copy, icon }) => (
  <>
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
    <PageLayout
      background={BACKGROUND_WHITE}
      hideSocial={true}
      hideAbout={true}
      hideCopyToClipboard={true}
    >
      <div className="center-inner">
        <div className="center-inner">
          <h2 className={styles.title}>{title}</h2>
          <span
            className={Classnames(styles.copy, 'sub-copy')}
            dangerouslySetInnerHTML={{ __html: copy }}
          />
          {icon && (
            <Icon id="error-icon" type={ICON_ERROR} className="center" />
          )}
        </div>
      </div>
    </PageLayout>
  </>
);

ErrorPage.propTypes = {
  pageTitle: string.isRequired,
  title: string.isRequired,
  copy: string.isRequired,
  icon: bool,
};

ErrorPage.defaultProps = {
  icon: false,
};

export default memo(ErrorPage);
