/* eslint-disable no-undef */
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import ErrorBoundary from '../../components/error-boundary';
// import Terms from '../../components/terms-dialog';
import Config from '../../config';
import { AppContext } from '../../context/app';
import { HistoryPropertyTypes } from '../../prop-types';
import {
  ROUTE_HOME,
  ROUTE_ABOUT,
  ROUTE_QUESTIONS,
  ROUTE_RESULTS,
  ROUTE_PLAN_YOUR_FUTURE,
  ROUTE_BROSWER_SUPPORT,
  ROUTE_CAMERAERROR,
  ROUTE_PHOTO_UPLOAD,
} from '../../routes';
import About from '../about';
import BroswerSupport from '../browser-support';
import ErrorPage from '../error-page';
import Home from '../home';
import PhotoUpload from '../photo-upload';
import PlanYourFuture from '../plan-your-future';
import Questions from '../questions';
import Results from '../results';
import FacebookShareImage from './assets/facebook_share.jpg';
import TwitterShareImage from './assets/twitter_share.jpg';
import styles from './index.module.css';

const App = ({ history }) => {
  useEffect(() => {
    history.listen(location => {
      window.scrollTo(0, 0);
      const timer = window.setInterval(() => {
        if (window.utag != undefined && window.utag.view != undefined) {
          window.clearInterval(timer);
          window.utag.view({ CanonicalPath: location.pathname });
        }
      }, 100);
    });
  }, []);

  const canSupportBroswer = () => {
    // * SAMSUNG / UC / IE11
    if (
      navigator.userAgent.match(/Trident.*rv[ :]*11\./) ||
      navigator.userAgent.match(
        /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
      ) ||
      navigator.userAgent.indexOf(' UCBrowser/') >= 0
    ) {
      return false;
    }
    return true;
  };

  if (!canSupportBroswer()) {
    return (
      <Switch>
        <Redirect from="/" exact to={ROUTE_BROSWER_SUPPORT} />
        <Route exact path={ROUTE_BROSWER_SUPPORT} component={BroswerSupport} />
      </Switch>
    );
  }

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{`${Config.title}`}</title>
        <meta name="description" content={Config.description} />
        <meta property="og:title" content={Config.title} />
        <meta property="og:description" content={Config.description} />
        <meta property="og:image" content={FacebookShareImage} />
        <meta property="og:url" content={Config.siteUrl} />
        <meta name="twitter:title" content={Config.title} />
        <meta name="twitter:description" content={Config.description} />
        <meta name="twitter:image" content={TwitterShareImage} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <AppContext.Consumer>
        {({ questions, onQuestionUpdate, storeResults }) => (
          <>
            <main className={styles.main}>
              <Switch>
                <Route exact path={ROUTE_HOME} component={Home} />
                <Route
                  path={ROUTE_QUESTIONS}
                  extact
                  render={props => (
                    <Questions
                      {...props}
                      questions={questions}
                      onQuestionUpdate={onQuestionUpdate}
                      storeResults={storeResults}
                    />
                  )}
                />
                <Route path={ROUTE_ABOUT} component={About} />
                <Route path={ROUTE_RESULTS} component={Results} />
                <Route
                  path={ROUTE_PLAN_YOUR_FUTURE}
                  component={PlanYourFuture}
                />
                <Route exact path={ROUTE_CAMERAERROR} component={ErrorPage} />
                <Route
                  exact
                  path={ROUTE_PHOTO_UPLOAD}
                  component={PhotoUpload}
                />
                <Route component={ErrorPage} />
              </Switch>
            </main>
          </>
        )}
      </AppContext.Consumer>
    </ErrorBoundary>
  );
};

App.propTypes = {
  history: HistoryPropertyTypes,
};

export default withRouter(memo(App));
