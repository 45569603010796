import { func, number } from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RATIO_IMAGE_1 } from '../../../components/image/constants';
import PageLayout from '../../../components/page-layout';
import QuestionHeader from '../../../components/question-header';
import SliderQuestion from '../../../components/slider-question';
import Config from '../../../config';
import { QUESTION_AGE } from '../../../constants/fields';
import { CopyContext } from '../../../context/copy';
import { ROUTE_QUESTIONS_TWO, ROUTE_HOME } from '../../../routes';

const MIN = 18;
const MAX = 68;

const QuestionOne = ({ value, onChange }) => {
  const [progress, updateProgress] = useState(0);
  return (
    <CopyContext.Consumer>
      {({ questionOne }) => (
        <>
          <Helmet>
            <title>{`${Config.title} | Question 1`}</title>
          </Helmet>
          <PageLayout backLink={ROUTE_HOME}>
            <div id="question-one" className="center-inner">
              <QuestionHeader
                question={1}
                title={questionOne.title}
                imgRatio={RATIO_IMAGE_1}
                progress={progress}
              />
              <SliderQuestion
                className="flex-grow"
                toLink={ROUTE_QUESTIONS_TWO}
                min={MIN}
                max={MAX}
                value={value}
                onChange={onChange}
                question={QUESTION_AGE}
                copy={questionOne['copy']}
                ctaCopy={questionOne.cta}
                gaLabel="age-current"
                backLink={ROUTE_HOME}
                showMax
                updateProgress={updateProgress}
              />
            </div>
          </PageLayout>
        </>
      )}
    </CopyContext.Consumer>
  );
};

QuestionOne.propTypes = {
  value: number.isRequired,
  onChange: func.isRequired,
};

export default QuestionOne;
