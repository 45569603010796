const RATIO_SQUARE = 'square';
const RATIO_LOGO = 'logo';
const RATIO_FULL_WIDTH = 'full-width';
const RATIO_IMAGE_1 = 'image-1';
const RATIO_IMAGE_2 = 'image-2';
const RATIO_IMAGE_3 = 'image-3';
const RATIO_IMAGE_4 = 'image-4';
const RATIO_IMAGE_SUMMARY = 'image-summary';
const RATIO_FULL_HEIGHT = 'full-height';

export {
  RATIO_SQUARE,
  RATIO_LOGO,
  RATIO_FULL_WIDTH,
  RATIO_IMAGE_1,
  RATIO_IMAGE_2,
  RATIO_IMAGE_3,
  RATIO_IMAGE_4,
  RATIO_IMAGE_SUMMARY,
  RATIO_FULL_HEIGHT,
};
