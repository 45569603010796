/* eslint-disable compat/compat */

/**
 * @type {Object}
 * @property {MediaDeviceInfo.kind} VIDEO
 * @property {MediaDeviceInfo.kind} AUDIO
 */
const MEDIA_DEVICE_INPUT_TYPES = {
  VIDEO: 'videoinput',
  AUDIO: 'audioinput',
};

/**
 * @type {Object}
 * @property {boolean} video
 * @property {boolean} audio
 */
const PERMISSION_CONSTRAINTS = {
  video: {
    facingMode: 'user',
  },
  audio: false,
};

function isFacebookApp() {
  if (typeof window !== 'undefined' && navigator) {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
  }
  return false;
}

/** @return {boolean} */
function isHTTPS() {
  if (typeof window !== 'undefined') {
    return window.location.protocol === 'https:';
  } else {
    return false;
  }
}

/**
 * Determine if we can loop through the connected media devices based on some
 *    feature-checking.
 * @return {boolean}
 */
function canEnumerateDevices() {
  if (typeof window !== 'undefined' && navigator && isHTTPS()) {
    return (
      !!navigator.mediaDevices &&
      !!navigator.mediaDevices.enumerateDevices &&
      !!window.MediaStreamTrack
    );
  }

  return false;
}

/**
 * Get all available media devices.
 * @async
 * @return {Promise<Array<?MediaDeviceInfo>>}
 */
async function getMediaDevices() {
  if (canEnumerateDevices()) {
    return await navigator.mediaDevices.enumerateDevices();
  } else {
    return [];
  }
}

/**
 * @param {MediaDeviceInfo} mediaDevice
 * @return {boolean}
 */
function mediaDeviceIsCamera(mediaDevice) {
  return mediaDevice.kind === MEDIA_DEVICE_INPUT_TYPES.VIDEO;
}

/**
 * Detect if the user has a usable camera.
 * @async
 * @return {Promise<boolean>}
 */
export async function hasCamera() {
  const mediaDevices = await getMediaDevices();

  // Returns `true` if there is at least 1 media device that can capture video.
  return mediaDevices.filter(mediaDeviceIsCamera).length >= 1;
}

/**
 * Request permission to use the camera.
 * This will enable the camera for roughly a second and then close it down
 *    again. This is necessary to "store" the user's permission.
 * @return {Promise<MediaStream|boolean>}
 */
export async function requestUserMediaPermission() {
  const hasCam = await hasCamera();
  
  // Quick way of detecting Facebook's in-app browser.
  if (isFacebookApp() || !hasCam) {
    return false;
  }

  try {
    return await requestStream();
  } catch {
    return false;
  }
}

/**
 * @return {Promise<MediaStream>}
 */
export function requestStream() {
  return navigator.mediaDevices.getUserMedia(PERMISSION_CONSTRAINTS);
}

/**
 * @param {MediaStream} stream
 */
export function stopStream(stream) {
  stream.getTracks()[0].stop();
}

/* eslint-enable compat/compat */
