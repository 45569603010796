import { node } from 'prop-types';
import React, { Component } from 'react';
import {
  QUESTION_AGE,
  QUESTION_RETIREMENT_AGE,
  QUESTION_MONTHLY_CONTRIBUTION,
  QUESTON_PENSION_POT,
  QUESTION_RETIREMENT_INCOME,
} from '../constants/fields';

// Utils
import qs from '../utils/query-string';

export const AppContext = React.createContext();

const defaultStates = {
  questions: {
    [QUESTION_AGE]: 30,
    [QUESTION_RETIREMENT_AGE]: 80, // TODO: PUT IN AGE
    [QUESTION_MONTHLY_CONTRIBUTION]: 250,
    [QUESTON_PENSION_POT]: 28000,
    [QUESTION_RETIREMENT_INCOME]: 25000,
  },
  results: {},
  isRVtablet: false,
};

class AppProvider extends Component {
  static propTypes = {
    children: node.isRequired,
  };

  constructor(props) {
    super(props);

    const isRVtablet = this.getCurrentisRVtabletValue();

    this.state = {
      questions: {
        [QUESTION_AGE]: 30,
        [QUESTION_RETIREMENT_AGE]: 80, // TODO: PUT IN AGE
        [QUESTION_MONTHLY_CONTRIBUTION]: 250,
        [QUESTON_PENSION_POT]: 28000,
        [QUESTION_RETIREMENT_INCOME]: 25000,
      },
      finalImage: null,
      results: {},
      isRVtablet: isRVtablet,
      resetState: () => {
        // Reset all state with default values except isRVtablet value is carried over
        this.setState(
          Object.assign({}, defaultStates, {
            isRVtablet: this.state.isRVtablet,
          })
        );
      },
      setRVtablet: isRVtablet => this.setState({ isRVtablet }),
      onQuestionUpdate: (question, value) => {
        const { questions } = this.state;
        this.setState({
          questions: {
            ...questions,
            [question]: value,
          },
        });
      },
      storeResults: results => this.setState({ results }),
    };
  }

  getCurrentisRVtabletValue() {
    // Get current isRVtablet value from query string
    if (typeof window === 'undefined') return false;

    const parsed = qs.parse(window.location.search);

    return parsed && parsed.isRVtablet !== undefined;
  }

  render() {
    const { children } = this.props;

    return (
      <AppContext.Provider value={this.state}>
        {children}
      </AppContext.Provider>
    );
  }
}
export default AppProvider;
