import { number } from 'prop-types';
import React from 'react';
import styles from './index.module.css';

const QuestionNumber = ({ question }) => (
  <div className={styles.root}>
    <span className="question-number">{`${question}/4`}</span>
  </div>
);

QuestionNumber.propTypes = {
  question: number.isRequired,
};

export default QuestionNumber;
