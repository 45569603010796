import Classnames from 'classnames';
import { string, func } from 'prop-types';
import React from 'react';
import { Transition } from 'react-transition-group';
import styles from './index.module.css';

const ErrorDialog = ({ errorMessage, clearError }) => (
  <Transition
    in={errorMessage ? true : false}
    timeout={{
      enter: 100,
      exit: 0,
    }}
    onEntered={() => {
      setTimeout(() => {
        clearError('');
      }, 3000);
    }}
  >
    {state => (
      <div
        className={Classnames(
          styles.root,
          {
            [styles.entering]: state == 'entering',
            [styles.entered]: state == 'entered',
            [styles.exiting]: state == 'exiting',
          },
          'terms-dialog'
        )}
      >
        {errorMessage}
      </div>
    )}
  </Transition>
);

ErrorDialog.propTypes = {
  errorMessage: string.isRequired,
  clearError: func.isRequired,
};

export default ErrorDialog;
