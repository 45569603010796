import React from 'react';
import GoalsImage from '../../containers/questions/summary/assets/goals.png';
import Image from '../image';
import { RATIO_IMAGE_SUMMARY } from '../image/constants';
import PageLayout from '../page-layout';
import styles from './index.module.css';

function LoadingScreen() {
  return (
    <PageLayout
        hideCopyToClipboard={true}>
      <div className="center-inner">
        <div className={styles.wrapper}>
          <div className={styles.imageWrapper}>
            <Image
              src={GoalsImage}
              altText="Sun chair"
              ratio={RATIO_IMAGE_SUMMARY}
              className="question-img"
            />
          </div>

          <div className={styles.bar}>
            <div className={`${styles.progress} progress`}/>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default LoadingScreen;
