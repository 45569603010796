import Imagepack from 'imagepack/dist/imagepack.js';
import { number, PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';

import brokenPiggyBank from './assets/broken-piggy-bank.bin';
import cake from './assets/cake.bin';
import piggyBank from './assets/piggy-bank.bin';
import wallet from './assets/wallet.bin';

import styles from './index.module.css';

/** The frames the animation should pause on. */
const frameDefs = {
  '1': [0, 39, 54, 74, 124],
  '2': [0, 43, 73, 96, 124],
  '3': [0, 46, 64, 79, 124],
  '4': [0, 37, 66, 84, 124],
};

class QuestionAnimation extends PureComponent {
  constructor(props) {
    super(props);

    /** @type {number} */
    this.FPS = 60;
    /** @type {Date} */
    this.currentDate = new Date();
    /** @type {number} */
    this.currentFrame = this.getFrame(this.props.progress);

    /** @type {?Imagepack} */
    this.imagepack = null;

    /**
     * Path to the imagepack bin file.
     * @type {string}
     */
    this.animationPackPath = '';

    /**
     * Collection of filenames in the image pack.
     * @type {Array<string>}
     */
    this.fileNames = [];

    /** @type {number} */
    this.frameWidth = 1;
    this.frameHeight = 1;
  }

  static propTypes = {
    question: PropTypes.number,
    progress: PropTypes.number,
  };

  componentDidMount() {
    switch (this.props.question) {
      case 1:
        this.animationPackPath = cake;
        this.frameWidth = 350;
        this.frameHeight = 376;
        break;
      case 2:
        this.animationPackPath = piggyBank;
        this.frameWidth = 318;
        this.frameHeight = 380;
        break;
      case 3:
        this.animationPackPath = wallet;
        this.frameWidth = 440;
        this.frameHeight = 440;
        break;
      case 4:
        this.animationPackPath = brokenPiggyBank;
        this.frameWidth = 480;
        this.frameHeight = 286;
        break;
    }

    this.imagepack = new Imagepack({ verbose: false });

    this.imagepack.once('load', fileNames => {
      this.fileNames = fileNames;
      this.image.width = this.frameWidth;
      this.image.height = this.frameHeight;
      this.image.style.width = `${this.frameWidth / 2}px`;
      this.image.style.height = `${this.frameHeight / 2}px`;
      this.image.src = this.imagepack.getURI(this.fileNames[this.currentFrame]);
      this.updateAnimation();
    });

    this.imagepack.load(this.animationPackPath);
  }

  componentDidUpdate(prevProps) {
    const { progress } = this.props;
    if (prevProps.progress !== progress) {
      this.updateAnimation();
    }
  }

  updateAnimation = () => {
    if (typeof window === 'undefined' || this.image === null) return;

    const { progress } = this.props;
    const targetFrame = this.getFrame(progress);
    const date = new Date();
    const minDelta = (1 / this.FPS) * 1000;

    if (date - this.currentDate >= minDelta) {
      this.currentDate = date;

      if (this.currentFrame > targetFrame) {
        this.currentFrame--;
      } else if (this.currentFrame < targetFrame) {
        this.currentFrame++;
      }

      if (this.imagepack && this.fileNames.length > 0) {
        this.image.src = this.imagepack.getURI(
          this.fileNames[this.currentFrame]
        );
      }
    }

    this.raf = null;
    if (targetFrame === this.currentFrame) {
      window.cancelAnimationFrame(this.raf);
    } else {
      this.raf = window.requestAnimationFrame(this.updateAnimation);
    }
  };

  /**
   * Calculate which frame number we need to display.
   * @param value
   * @return {number}
   */
  getFrame(value) {
    const { question } = this.props;
    const intervals = [0, 10, 25, 35, 70];
    const output = intervals.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
    const index = intervals.indexOf(output);
    return frameDefs[question][index];
  }

  render() {
    return (
      <div>
        <img
          alt="animation"
          aria-hidden={true}
          className={styles.image}
          ref={element => (this.image = element)}
        />
      </div>
    );
  }
}

QuestionAnimation.propTypes = {
  question: number.isRequired,
  progress: number.isRequired,
};

export default QuestionAnimation;
