const ROUTE_HOME = '/';
const ROUTE_ABOUT = '/about';
const ROUTE_QUESTIONS = '/questions';
const ROUTE_RESULTS = '/results';
const ROUTE_PLAN_YOUR_FUTURE = '/plan-your-future';
const ROUTE_BROSWER_SUPPORT = '/browser-support';
const ROUTE_SNAPSHOT = '/snapshot';
const ROUTE_CAMERAERROR = '/camera-error';

const ROUTE_QUESTIONS_ONE = `${ROUTE_QUESTIONS}/one`;
const ROUTE_QUESTIONS_TWO = `${ROUTE_QUESTIONS}/two`;
const ROUTE_QUESTIONS_THREE = `${ROUTE_QUESTIONS}/three`;
const ROUTE_QUESTIONS_FOUR = `${ROUTE_QUESTIONS}/four`;
const ROUTE_QUESTIONS_SUMMARY = `${ROUTE_QUESTIONS}/summary`;

const ROUTE_PHOTO_UPLOAD = `/photo-upload`;

export {
  ROUTE_HOME,
  ROUTE_ABOUT,
  ROUTE_QUESTIONS,
  ROUTE_RESULTS,
  ROUTE_PLAN_YOUR_FUTURE,
  ROUTE_BROSWER_SUPPORT,
  ROUTE_QUESTIONS_ONE,
  ROUTE_QUESTIONS_TWO,
  ROUTE_QUESTIONS_THREE,
  ROUTE_QUESTIONS_FOUR,
  ROUTE_QUESTIONS_SUMMARY,
  ROUTE_PHOTO_UPLOAD,
  ROUTE_SNAPSHOT,
  ROUTE_CAMERAERROR,
};
