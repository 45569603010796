/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Classnames from 'classnames';
import { bool, string, object, func } from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Logo from '../../components/header/assets/logo-alternative.png';
import LogoBlack from '../../components/header/assets/logo.png';
import Icon from '../../components/icon';
import {
  ICON_CLOSE,
  ICON_COLOR_WHITE,
  ICON_PRINT,
  ICON_SMALL,
  ICON_LARGE,
} from '../../components/icon/constant';
import ImageComponent from '../../components/image';
import {
  RATIO_FULL_HEIGHT,
  RATIO_LOGO,
} from '../../components/image/constants';
import { AppContext } from '../../context/app';
import { CopyContext } from '../../context/copy';
import { FaceFilterContext } from '../../context/facefilter';
import { HistoryPropertyTypes } from '../../prop-types';
import result from '../../prop-types/result';
import { ROUTE_HOME, ROUTE_RESULTS } from '../../routes';
import styles from './index.module.css';
import RadialLoader from './radial-loader';

const CANVAS_PRINT_HEIGHT = 600;
const CANVAS_PRINT_WIDTH = 400;

@withRouter
class Snapshot extends PureComponent {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.canvasPrint = React.createRef();
    this.snapShotTimer = 2000;

    this.state = {
      counter: this.snapShotTimer,
    };
  }

  
  static propTypes = {
    history: HistoryPropertyTypes,
    isRVtablet: bool.isRequired,
    results: object,
    snapshot: string,
    snapshotWithPostProcessing: string,
    setSnapshotWithPostProcessing: func,
    onClose: func.isRequired,
    onTouchCancelled: func,
  };

  canvas;
  #buttonPressTimer;
  #intervalHandler;

  reset = () => {
    clearTimeout(this.#buttonPressTimer);
    clearInterval(this.#intervalHandler);
    this.setState({
      counter: this.snapShotTimer,
    });
  };

  printAction = (results, snapshot) => {
    /* Create a canvas with postcard print only visible on print (@print) */
    this.handleButtonPress(results, snapshot, true);

    /* Do Printer API call */
    // Little delay for canvas to render
    setTimeout(() => window.print(), 1000);
  };

  countDown = () => {
    const { counter } = this.state;

    this.setState({ counter: this.state.counter - 10 });

    if (counter === 0) {
      clearInterval(this.#intervalHandler);
      this.setState({
        counter: 0,
      });
    }
  };

  handleButtonPress = (results, snapshot, shareImage = false, createPrintPicture = false) => {
    // this.setState({ isActive: true });
    if (!createPrintPicture || !shareImage)
      this.#intervalHandler = setInterval(this.countDown, 10);
    
    // Prevent infinite loop.
    if (shareImage) {
      clearInterval(this.#intervalHandler);
    }

    const delayTimeout = createPrintPicture || shareImage ? 0 : this.snapShotTimer;
    const canvasRef = createPrintPicture ? this.canvasPrint : this.canvas;

    this.#buttonPressTimer = setTimeout(() => {
      let imagesLoaded = 0;

      const drawAge = (ctx, width, height) => {
        const circleWidth = 70;

        const centerX = width / 2;
        const centeY = height - (circleWidth + 50);
        const color = 'rgba(219, 105, 108, 0.6)';

        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.arc(centerX, centeY, circleWidth, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.strokeStyle = color;
        ctx.lineWidth = 0;
        ctx.stroke();
        ctx.fill();

        ctx.font = '30pt giorgiosans';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.fillText(
          results['current_retirement_prediction'],
          centerX,
          centeY + 35
        );
        ctx.fillText('AGE', centerX, centeY);
      };

      const loadImage = (src, onload) => {
        var img = new Image();
        img.addEventListener('load', onload);
        img.crossOrigin = 'anonymous';
        img.src = src;
        return img;
      };

      const main = () => {
        imagesLoaded += 1;

        if (imagesLoaded === 1) {
          const width = img1.width;
          const height = img1.height;

          const ctx = canvasRef.current.getContext('2d');

          if (createPrintPicture) {
            canvasRef.current.width = CANVAS_PRINT_WIDTH;
            // Canvas for printing in RV Tablet mode
            canvasRef.current.height = CANVAS_PRINT_HEIGHT;

            ctx.clearRect(0, 0, CANVAS_PRINT_WIDTH, CANVAS_PRINT_HEIGHT);

            // Draw logo
            const topLogo = 20;
            
            // Draw "Meet your future self"
            const textTitle = 'Meet #YourFutureSelf';
            const topTextTitle = topLogo + 50;
            ctx.font = '30pt giorgiosans';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.fillText(
              textTitle.toUpperCase(),
              CANVAS_PRINT_WIDTH / 2,
              topTextTitle
            );

            // draw picture
            const padding = 30;
            const topPicture = topTextTitle + padding;

            // Draw a square and center the image within
            const pictureSize = Math.min(width, height);
            const left = (width - pictureSize) / 2;
            const top = (height - pictureSize) / 2;

            const widthPicture = CANVAS_PRINT_WIDTH - 2 * padding;
            const heightPicture = widthPicture; // height / (width / widthPicture);

            ctx.drawImage(
              img1,
              left,
              top,
              pictureSize,
              pictureSize,
              padding,
              topPicture,
              widthPicture,
              heightPicture
            );

            // Draw age
            const textResult = `Here you are at ${
              results['current_retirement_prediction']
            }`;
            const topTextResult = topPicture + heightPicture + 40;
            ctx.font = '20pt giorgiosans';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.fillText(textResult, CANVAS_PRINT_WIDTH / 2, topTextResult);

            // Draw bottom note
            const textBottomNote = `To help plan for your future visit`;
            const topTextBottomNote = CANVAS_PRINT_HEIGHT - 37;
            ctx.font = '10pt unitroundedpro';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.fillText(
              textBottomNote,
              CANVAS_PRINT_WIDTH / 2,
              topTextBottomNote
            );

            // Draw bottom link
            const textBottomLink = `https://www.scottishwidows.co.uk`;
            const topTextBottomLink = CANVAS_PRINT_HEIGHT - 22;
            ctx.font = '10pt unitroundedpro';
            ctx.fillStyle = 'blue';
            ctx.textAlign = 'center';
            ctx.fillText(
              textBottomLink,
              CANVAS_PRINT_WIDTH / 2,
              topTextBottomLink
            );
          } else {
            canvasRef.current.width = width;
            canvasRef.current.height = height;

            ctx.drawImage(img1, 0, 0, img1.width, img1.height);
            
            drawAge(ctx, width, height);
          }
          
          if (shareImage && !this.props.snapshotWithPostProcessing) {
            const imageSrc = this.canvas.current.toDataURL('image/jpeg');
            this.props.setSnapshotWithPostProcessing(imageSrc);
          }
        }
      };

      const img1 = loadImage(snapshot, main);
    }, delayTimeout);
  };
  
  capture = () => {
    const dataString = this.canvas.current.toDataURL('image/jpeg');
    const image = new Image();
    image.src = dataString;

    const link = document.createElement('a');
    link.download = 'image.jpg';
    link.href = dataString;
    
    /**
     * "display: none" prevents Firefox from downloading the image.
     * So instead we are making it a "visible" link, out of the viewport.
     */
    link.style.opacity = '0.01';
    link.style.position = 'absolute';
    link.style.transform = 'translate(100vmax, 100vmax)';
    document.body.appendChild(link);
    link.click();
    
    this.reset();
  };
  
  componentDidUpdate(prevProps, prevState) {
    const isDesktop = window.matchMedia('(min-width: 767px)').matches;
    const isEdge = /Edge/.test(navigator.userAgent);
    
    // If the counter reaches zero, then we want to download the image.
    if (!isEdge && isDesktop && prevState.counter > 0 && this.state.counter === 0) {
      this.capture();
    }
  }
  
  componentWillUnmount() {
    clearInterval(this.#intervalHandler);
    this.props.setSnapshotWithPostProcessing(null);
  }

  render() {
    const { counter } = this.state;
    const {
      history,
      isRVtablet,
      results,
      onClose,
      snapshot,
      snapshotWithPostProcessing,
    } = this.props;
    
    if (Object.entries(results).length === 0) return null;
    if (!snapshot) history.push(ROUTE_HOME);
    
    const isDesktop = window.matchMedia('(min-width: 767px)').matches;
    // forgive me
    const isEdge = /Edge/.test(navigator.userAgent);
    const progress = ((this.snapShotTimer - counter) / this.snapShotTimer) * 100;

    if (!isEdge && !isDesktop && !snapshotWithPostProcessing) {
      this.handleButtonPress(results, snapshot, true);
    }
    
    if (isEdge && !snapshotWithPostProcessing) {
      this.handleButtonPress(results, snapshot, true);
    }

    return (
      <CopyContext.Consumer>
        {({ snapshot: snapshotCopy }) => (
          <div className={styles.root}>
            {/* Print button*/}
            {isRVtablet && (
              <button
                className={styles.print}
                aria-label="Print your snapshot"
                onClick={() => this.printAction(results, snapshot)}
              >
                <Icon
                  type={ICON_PRINT}
                  color={ICON_COLOR_WHITE}
                  size={ICON_LARGE}
                />
              </button>
            )}

            {/* <div className={styles.logoWrapper}>
              <ImageComponent
                src={Logo}
                lazyLoad={false}
                ratio={RATIO_LOGO}
                id="logo"
                altText="Scottish Widow Logo"
              />
            </div> */}
            
            <div
              className={`${styles.imgWrapper} ${styles.forceLayer}`}
              onTouchStart={() => {
                if (!isEdge && !isRVtablet && isDesktop) {
                  this.handleButtonPress(results, snapshot);
                }
              }}
              onTouchEnd={() => !isRVtablet && this.reset()}
              onMouseDown={() => {
                if (!isEdge && !isRVtablet) {
                  this.handleButtonPress(results, snapshot);
                }
              }}
              onMouseUp={() => !isRVtablet && this.reset()}
              onMouseLeave={() => !isRVtablet && this.reset()}
              onClick={() => {}}
            >
              <ImageComponent
                className={Classnames(styles.image, styles.forceLayer, 'snapshot-image')}
                src={snapshot}
                ratio={RATIO_FULL_HEIGHT}
              />
            </div>

            {snapshotWithPostProcessing && (
              <div className={`${styles.imgWrapper} ${styles.finalImage}`}>
                <ImageComponent
                  src={snapshotWithPostProcessing}
                  className={styles.forceLayer}
                  ratio={RATIO_FULL_HEIGHT}
                  onTouchCancel={this.props.onTouchCancelled}
                />
              </div>
            )}

            <button
              id="snapshot-close"
              type="button"
              aria-label="Close"
              className={styles.close}
              onClick={onClose}
            >
              <Icon
                type={ICON_CLOSE}
                color={ICON_COLOR_WHITE}
                size={ICON_SMALL}
              />
            </button>

            <div className={styles.captureWrapper}>
              <div className={styles.ageDisc}>
                <div className={styles.disc} />
                <div className={styles.discInfo}>
                  <span>Age</span>
                  <span id="snapshot-age">
                    {results['current_retirement_prediction']}
                  </span>
                </div>
              </div>
              <div className={styles.radialLoaderWrapper}>
                <RadialLoader
                  progress={progress ? progress : 0}
                  radius={63}
                  stroke={5}
                />
              </div>
              {!isRVtablet && (
                <div>
                  <p
                    className="snapshot-copy"
                    dangerouslySetInnerHTML={{ __html: isEdge ? 'Right click + save as' : snapshotCopy['copy'] }}
                  />
                </div>
              )}
            </div>

            <canvas ref={this.canvas} className={styles.canvas} />
            {isRVtablet && (
              <canvas
                ref={this.canvasPrint}
                className={`${styles.canvas} ${styles.canvasPrint}`}
              />
            )}
          </div>
        )}
      </CopyContext.Consumer>
    );
  }
}

export default Snapshot;
