import Classnames from 'classnames';
import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from './index.module.css';

const Footer = () => {
  return (
    <footer className={Classnames(styles.root)}>
      <nav>
        <ul className={`${styles.itemList}`}>
          <li className={`${styles.item}`}>
            <a
              className={`${styles.link}`}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.scottishwidows.co.uk/legal-information/cookies/"
            >
              Cookies
            </a>
          </li>
          <li className={`${styles.item}`}>
            <a
              className={`${styles.link}`}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.scottishwidows.co.uk/legal-information/"
            >
              Legal Information
            </a>
          </li>
          <li className={`${styles.item}`}>
            <a
              className={`${styles.link}`}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.scottishwidows.co.uk/about_us/serving_our_customers/accessibility.html"
            >
              Accessibility
            </a>
          </li>
          <li className={`${styles.item}`}>
            <a
              className={`${styles.link}`}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.scottishwidows.co.uk/index.html"
            >
              Home
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default withRouter(Footer);
