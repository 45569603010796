import * as Sentry from '@sentry/browser';
import { node } from 'prop-types';
import React, { Component } from 'react';
import Config from '../../config';
import { CopyContext } from '../../context/copy';
import ErrorPage from '../error-page';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static contextType = CopyContext;

  static propTypes = {
    children: node.isRequired,
  };

  componentDidCatch(err) {
    this.setState({ hasError: true });
    Sentry.captureException(err);
  }

  render() {
    const {
      errorBoundary: { title, copy },
    } = this.context;

    if (this.state.hasError) {
      return (
        <ErrorPage
          pageTitle={`${Config.title} | Browser Support`}
          title={title}
          copy={copy}
        />
      );
    }
    return this.props.children;
  }
}
