import { string } from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../icon';
import { ICON_BACK_ARROW, ICON_COLOR_WHITE } from '../../icon/constant';

const BackButton = ({ backLink }) => (
  <Link to={backLink} className="back-button" aria-label="Go back">
    <Icon type={ICON_BACK_ARROW} color={ICON_COLOR_WHITE} />
  </Link>
);

BackButton.propTypes = {
  backLink: string.isRequired,
};

export default BackButton;
