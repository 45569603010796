import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './index.module.css';

const CountDown = ({ takePicture }) => {
  const [flashed, setFlashed] = useState(false);

  // on first render
  useEffect(() => {
    // After 3 seconds, take the picture
    if (typeof window === 'undefined') return () => {};

    let sto = setTimeout(() => {
      if (sto) clearTimeout(sto);
      sto = null;

      // set the flash
      setFlashed(true);

      // take the picture!
      setTimeout(() => {
        takePicture();
      }, 200);
    }, 3000);

    return () => {
      if (sto) clearTimeout(sto);
      sto = null;
    };
  }, []);

  return (
    <div className={styles.container}>
      <span className={`${styles.count} ${styles.one}`}>1</span>
      <span className={`${styles.count} ${styles.two}`}>2</span>
      <span className={`${styles.count} ${styles.three}`}>3</span>
      <div className={`${styles.flash} ${flashed ? styles.flashed : ''}`} />
    </div>
  );
};

CountDown.propTypes = {
  // history: HistoryPropertyTypes,
  takePicture: func.isRequired,
};

export default CountDown;
