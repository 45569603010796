import * as Sentry from '@sentry/browser';
import { func } from 'prop-types';
import React, { PureComponent } from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';
import Config from '../../config';

let Calculation;
if (process.env.REACT_APP_IS_STATIC) {
  Calculation = require('scottish-widows-calculation');
}

import {
  QUESTION_AGE,
  QUESTON_PENSION_POT,
  QUESTION_MONTHLY_CONTRIBUTION,
  QUESTION_RETIREMENT_INCOME,
} from '../../constants/fields';
import { CopyContext } from '../../context/copy';

import {
  MatchPropertyTypes,
  HistoryPropertyTypes,
  QuestionsPropTypes,
} from '../../prop-types';

import {
  ROUTE_QUESTIONS_ONE,
  ROUTE_QUESTIONS_TWO,
  ROUTE_QUESTIONS_THREE,
  ROUTE_QUESTIONS_FOUR,
  ROUTE_QUESTIONS_SUMMARY,
  ROUTE_RESULTS,
} from '../../routes';

import QuestionFour from './question-four';
import QuestionOne from './question-one';
import QuestionThree from './question-three';
import QuestionTwo from './question-two';
import Summary from './summary';

@withRouter
class Questions extends PureComponent {
  static contextType = CopyContext;

  state = {
    isLoading: false,
    loadingComplete: false,
    isError: '',
  };

  static propTypes = {
    match: MatchPropertyTypes,
    history: HistoryPropertyTypes,
    questions: QuestionsPropTypes,
    onQuestionUpdate: func.isRequired,
    storeResults: func.isRequired,
  };

  componentWillUnmount() {
    this.setState({
      isError: '',
    });
  }

  onSubmit = async () => {
    const { history, questions, storeResults } = this.props;

    try {
      this.setState({ isLoading: true });

      if (typeof Calculation !== 'undefined') {
        // we can run the calculation client-side
        try {
          const c = new Calculation(questions);
          const data = c.calculate();
          storeResults(data);
        } catch (error) {
          this.setState({ isError: error.message });
          Sentry.captureException(error.message);
          return;
        }

        history.push(ROUTE_RESULTS);
      } else {
        // request calculation on server
        const res = await fetch(Config.endpoint, {
          method: 'POST',
          body: JSON.stringify(questions),
        });
        const data = await res.json();

        if (data) {
          setTimeout(() => {
            this.setState({ isLoading: false, loadingComplete: true });
            if (data.success) {
              storeResults(data.data);
              setTimeout(() => {
                history.push(ROUTE_RESULTS);
              }, 50);
            } else {
              this.setState({ isError: data.message, loadingComplete: false });
            }
          }, 2000);
        } else {
          this.setState({
            isError: 'Something went wrong',
            isLoading: false,
            loadingComplete: false,
          });
        }
      }
    } catch (error) {
      this.setState({
        isError: this.context.error.fetch,
        isLoading: false,
        loadingComplete: false,
      });
      Sentry.captureException(error.message);
    }
  };

  render() {
    const { match, questions, onQuestionUpdate } = this.props;
    const { isLoading, loadingComplete, isError } = this.state;
    return (
      <>
        <Switch>
          <Route
            path={ROUTE_QUESTIONS_ONE}
            extact
            render={props => (
              <QuestionOne
                {...props}
                onChange={onQuestionUpdate}
                value={questions[QUESTION_AGE]}
              />
            )}
          />
          <Route
            path={ROUTE_QUESTIONS_TWO}
            extact
            render={props => (
              <QuestionTwo
                {...props}
                onChange={onQuestionUpdate}
                value={questions[QUESTION_MONTHLY_CONTRIBUTION]}
                currentAge={questions[QUESTION_AGE]}
              />
            )}
          />
          <Route
            path={ROUTE_QUESTIONS_THREE}
            extact
            render={props => (
              <QuestionThree
                {...props}
                onChange={onQuestionUpdate}
                value={questions[QUESTION_RETIREMENT_INCOME]}
              />
            )}
          />
          <Route
            path={ROUTE_QUESTIONS_FOUR}
            extact
            render={props => (
              <QuestionFour
                {...props}
                onChange={onQuestionUpdate}
                value={questions[QUESTON_PENSION_POT]}
              />
            )}
          />
          <Route
            path={ROUTE_QUESTIONS_SUMMARY}
            extact
            render={props => (
              <Summary
                {...props}
                onSubmit={this.onSubmit}
                isLoading={isLoading}
                loadingComplete={loadingComplete}
                isError={isError}
              />
            )}
          />
          <Redirect from={`${match.path}`} to={ROUTE_QUESTIONS_ONE} />
        </Switch>
      </>
    );
  }
}

export default Questions;
