import { node } from 'prop-types';
import React, { Component } from 'react';
import { hasCamera } from '../utils/camera';

export const FaceFilterContext = React.createContext();

/**
 * Purely to prevent magic values in our codebase.
 * @type {Object}
 * @property {string} IMAGE
 * @property {string} VIDEO
 */
export const MODES = {
  IMAGE: 'image',
  VIDEO: 'video',
};

/**
 * Not the calculated age, but the age to apply to the face filter.
 * @type {Object}
 */
export const DISPLAYED_AGE = {
  MAXIMUM: 90,
  MINIMUM: 50,
};

/**
 * We do not want the displayed age to be too young, or too old (to prevent
 *    horror scenarios).
 * @param {number|string} age
 */
export function keepGraphicsRealistic(age) {
  let displayedAge = age;

  if (age > DISPLAYED_AGE.MAXIMUM || age === 'Over 100') {
    displayedAge = DISPLAYED_AGE.MAXIMUM;
  } else if (age < DISPLAYED_AGE.MINIMUM) {
    displayedAge = DISPLAYED_AGE.MINIMUM;
  }

  return displayedAge;
}

/**
 * Returns the face-aging custom element if it is available.
 * @return {?Element}
 */
export function getFaceAgingElement() {
  return document.querySelector('scottishwidows-faceaging');
}

class FaceFilterProvider extends Component {
  static propTypes = {
    children: node.isRequired,
  };

  /**
   * @type {Object}
   * @property {boolean} gotCamera - Whether the current environment has at
   *    least 1 video-capture device.
   * @property {?MediaStream} videoStream
   * @property {boolean} gotPermission - Whether we got permission to
   *    getUserMedia.
   * @property {'video'|'image'} mode - Whether to show the WebGL
   *    experience in video mode or image mode.
   * @property {string} userImageSrc - An image taken by the user
   *    (straight from filesystem)
   * @property {string} snapshot - A snapshot with a GL layer on top.
   * @property {string} snapshotWithPostProcessing - A snapshot with GL +
   *    additional post-processing on top.
   */
  state = {
    gotCamera: false,
    gotPermission: true,
    stream: null,
    mode: MODES.VIDEO,
    userImageSrc: '',
    snapshot: '',
    snapshotWithPostProcessing: '',
    setPermission: gotPermission => this.setState({ gotPermission }),
    setStream: stream => this.setState({ stream }),
    setUserImageSrc: userImageSrc => this.setState({ userImageSrc }),
    setMode: mode => this.setState({ mode }),
    setSnapshot: snapshot => this.setState({ snapshot }),
    setSnapshotWithPostProcessing: snapshotWithPostProcessing =>
      this.setState({ snapshotWithPostProcessing }),
  };

  componentDidMount() {
    /** Preemptively detect whether the user has a camera. */
    hasCamera().then(gotCamera => this.setState({ gotCamera }));
  }

  render() {
    return (
      <FaceFilterContext.Provider value={this.state}>
        {this.props.children}
      </FaceFilterContext.Provider>
    );
  }
}

export default FaceFilterProvider;
