import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import ErrorPageComponent from '../../components/error-page';
import Config from '../../config';
import { CopyContext } from '../../context/copy';
import { HistoryPropertyTypes } from '../../prop-types';

const ErrorPage = ({ history }) => {
  return (
    <CopyContext.Consumer>
      {({ errorPage }) => (
        <ErrorPageComponent
          pageTitle={`${Config.title} | 404`}
          title={
            history.location.pathname === '/camera-error'
              ? errorPage.cameraErrorTitle
              : errorPage.title
          }
          copy={
            history.location.pathname === '/camera-error'
              ? errorPage.cameraErrorCopy
              : errorPage.copy
          }
          icon
        />
      )}
    </CopyContext.Consumer>
  );
};

ErrorPage.propTypes = {
  history: HistoryPropertyTypes,
};

export default withRouter(memo(ErrorPage));
