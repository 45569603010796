import { bool, func } from 'prop-types';
import React, { useContext, useState, useRef } from 'react';
import InlineSVG from 'svg-inline-react';
import FileUpload from '../../components/file-upload';
import PageLayout from '../../components/page-layout';
import BackButton from '../../components/ui/back-button';
import { CopyContext } from '../../context/copy';
import { FaceFilterContext } from '../../context/facefilter';
import { ROUTE_QUESTIONS_SUMMARY } from '../../routes';
import { generateImageForFaceFilter } from '../../utils/canvas';
import { validateFileSize } from '../../utils/file-input';

import tips from './assets/tips.svg';
import styles from './index.module.css';

const MAXIMUM_FILE_SIZE_IN_MB = 8;
const IMAGE_SIZE_FALLBACK = 1024;
const IMAGE_EXPORT_QUALITY = 0.92;

function PhotoUpload({ couldNotDetectFace, onValidFile }) {
  const { photoUpload: copy } = useContext(CopyContext);
  const { setUserImageSrc } = useContext(FaceFilterContext);
  const [error, setError] = useState('');

  const formRef = useRef(null);
  const canvasRef = useRef(null);

  const canvasSize =
    typeof window !== 'undefined'
      ? window.innerWidth * window.devicePixelRatio
      : IMAGE_SIZE_FALLBACK;

  /**
   * Whether the given file is valid.
   * @param {File} file
   * @return {boolean}
   */
  const checkValidity = file => {
    if (!file) {
      setError(copy.error_no_image);
      return false;
    }

    const isCorrectType =
      file.type === 'image/jpeg' || file.type === 'image/png';

    if (!validateFileSize(file.size, MAXIMUM_FILE_SIZE_IN_MB)) {
      const errorCopy = copy.error_exceeded_file_size.replace(
        '[MAX_FILE_SIZE_IN_MEGABYTES]',
        `${MAXIMUM_FILE_SIZE_IN_MB}MB.`
      );

      setError(errorCopy);
      return false;
    } else if (!isCorrectType) {
      setError(copy.error_wrong_file_type);
      return false;
    }

    /** Remove the error message if one is present. */
    if (error) setError('');
    return true;
  };

  /**
   * @param {Event} event
   * @return {Promise<void>}
   */
  const onChange = async event => {
    const input = /** @type {HTMLInputElement} */ (event.target);
    const form = /** @type {HTMLFormElement} */ (formRef.current);
    const canvas = /** @type {HTMLCanvasElement} */ (canvasRef.current);
    const file = input.files[0];
    const isValid = checkValidity(file);

    if (!isValid) {
      form.reset();
      return;
    }

    const exportOptions = {
      width: canvas.width,
      height: canvas.height,
      type: 'image/jpeg',
      quality: IMAGE_EXPORT_QUALITY,
    };

    form.reset();
    onValidFile();

    const userImage = await generateImageForFaceFilter(
      file,
      canvas,
      exportOptions
    );

    /** Store the userImage. */
    setUserImageSrc(userImage);
  };

  return (
    <PageLayout backLink={ROUTE_QUESTIONS_SUMMARY}>
      <div className="center-inner">
        <header className={styles.header}>
          {couldNotDetectFace ? (
            <>
              <h1 className={styles.heading}>
                {copy.error_could_not_detect_face_heading}
              </h1>
              <p>{copy.error_could_not_detect_face_body}</p>
            </>
          ) : (
            <>
              <h1 className={styles.heading}>{copy.heading}</h1>
              <p>{copy.body}</p>
              <div dangerouslySetInnerHTML={{ __html: copy.instructions }} />
            </>
          )}
        </header>

        <InlineSVG className={styles.illustration} src={tips} />

        <div>
          <div className={styles.buttonWrapper}>
            <div className={styles.back}>
              <BackButton backLink={ROUTE_QUESTIONS_SUMMARY} />
            </div>
            <form className="button" ref={formRef}>
              <FileUpload
                required
                accept="image/png, image/jpeg"
                id="file-upload"
                bodyText={copy.cta}
                className="button button-primary center"
                onChange={onChange}
              />
            </form>
          </div>
        </div>

        {/* Canvas to manipulate the image before sending it to the FaceFilter. */}
        <canvas ref={canvasRef} width={canvasSize} height={canvasSize} hidden />

        {error && <p>{error}</p>}
      </div>
    </PageLayout>
  );
}

PhotoUpload.propTypes = {
  couldNotDetectFace: bool,
  onValidFile: func,
};

PhotoUpload.defaultProps = {
  couldNotDetectFace: false,
  onValidFile: () => {},
};

export default PhotoUpload;
