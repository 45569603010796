/* eslint no-undef:0 */

import * as Sentry from '@sentry/browser';
import React, { useEffect } from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './containers/app';
import AppConsumer from './context/app';
import CopyConsumer from './context/copy';
import FaceFilterConsumer from './context/facefilter';
import 'focus-visible';
import 'intersection-observer';

if (process.env.NODE_ENV === 'production') {
  console.log('BUILD NUM : ', process.env.BUILD_NUM); // eslint-disable-line no-console
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://4ba6735a53b54b73a6513331094b2c21@sentry.io/1486634',
    release: process.env.BUILD_NUM,
    environment: process.env.NODE_ENV,
  });
}

const Application = () => {
  useEffect(() => {
    const timer = window.setInterval(() => {
      if (window.utag != undefined && window.utag.view != undefined) {
        window.clearInterval(timer);
        window.utag.view({ CanonicalPath: window.location.pathname });
      }
    }, 100);
  }, []);

  return (
    <BrowserRouter>
      <AppConsumer>
        <FaceFilterConsumer>
          <CopyConsumer>
            <App />
          </CopyConsumer>
        </FaceFilterConsumer>
      </AppConsumer>
    </BrowserRouter>
  );
};

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<Application />, rootElement);
} else {
  render(<Application />, rootElement);
}
