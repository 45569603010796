import * as Sentry from '@sentry/browser';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import Classnames from 'classnames';
import { oneOf, bool } from 'prop-types';
import React, {
  memo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import ErrorDialog from '../../../../components/error-dialog';
import Slider from '../../../../components/slider';
import { SLIDER_MONEY } from '../../../../components/slider/constant';
import Spinner from '../../../../components/spinner';
import Config from '../../../../config';
import {
  QUESTION_MONTHLY_CONTRIBUTION,
  QUESTION_RETIREMENT_INCOME,
} from '../../../../constants/fields';
import { AppContext } from '../../../../context/app';
import { keepGraphicsRealistic } from '../../../../context/facefilter';
import { SLIDERS_DESKTOP, SLIDERS_MOBILE } from './constant';
import styles from './index.module.css';

let Calculation;
if (process.env.REACT_APP_IS_STATIC) {
  Calculation = require('scottish-widows-calculation');
}

// Had to take it out of the function otherwise it stays null and triggers multiple fetch due to multiple Sliders instanciations
// Not using useState otherwise react batches the state update to later, cancelling the intented effect
let sto = null;

const Sliders = ({ type, sidePanel }) => {
  // const [timeout, updateTimeout] = useState(null);
  // const [timeoutWebComponent, updateTimeoutWebComponent] = useState(null);
  const [isLoading, updateIsLoding] = useState(false);
  const [hasError, updateHasError] = useState('');

  const controller = new AbortController();

  const { questions, storeResults, results, onQuestionUpdate } = useContext(
    AppContext
  );

  // On slider values changed, based on the app context
  useEffect(() => {
    // eslint-disable-next-line no-console
    // console.log('questions', questions);
    throttleFetch();
  }, [questions]);

  // When new results are stored, we can update the Web Component
  useEffect(() => {
    if (!results || typeof window === 'undefined') return;

    const { current_retirement_prediction } = results;
    const age = keepGraphicsRealistic(current_retirement_prediction);

    const scottishWidowsFaceAging = document.querySelector(
      'scottishwidows-faceaging'
    );

    if (scottishWidowsFaceAging && scottishWidowsFaceAging._scene)
      scottishWidowsFaceAging.age = age;
  }, [results]);

  useEffect(() => {
    // On will unmount
    return () => {
      controller.abort();
    };
  }, []);

  // So we don't redefine it on every render
  const onChange = useCallback(onQuestionUpdate);

  const throttleFetch = () => {
    if (typeof window === 'undefined') return;

    clearTimeout(sto);

    sto = setTimeout(() => {
      fetchData();
    }, 500);
  };

  const fetchData = async () => {
    try {
      // abord fetch anyway
      if (isLoading) controller.abort();

      updateIsLoding(true);
      updateHasError('');

      let data;
      if (typeof Calculation !== 'undefined') {
        // we can run the calculation client-side
        try {
          const c = new Calculation(questions);
          const results = c.calculate();
          data = { data: results, success: true };
        } catch (error) {
          updateHasError(error.message);
          updateIsLoding(false);
          Sentry.captureException(error.message);
          return;
        }
      } else {
        const res = await fetch(Config.endpoint, {
          method: 'POST',
          body: JSON.stringify(questions),
          signal: controller.signal,
        });
        data = await res.json();
      }

      if (data) {
        if (data.success) {
          storeResults(data.data);
        } else {
          updateHasError(data.message);
        }
      } else {
        updateHasError('Something went wrong');
      }
    } catch (error) {
      updateHasError(error.message);
      Sentry.captureException(error.message);
    } finally {
      updateIsLoding(false);
    }
  };

  return (
    <div className={Classnames(styles.root, styles[type])}>
      <div className={styles.sliderWrapper}>
        <Slider
          min={0}
          max={1500}
          value={questions[QUESTION_MONTHLY_CONTRIBUTION] || 0}
          onChange={onChange || (() => {})}
          question={QUESTION_MONTHLY_CONTRIBUTION}
          label="MONTHLY PENSION CONTRIBUTION"
          type={SLIDER_MONEY}
          gaLabel="adjust-contribution"
          gaCategory="results"
          sidePanel={sidePanel}
          showMax
        />
      </div>
      <div className={styles.sliderWrapper}>
        <Slider
          min={5000}
          max={100000}
          value={questions[QUESTION_RETIREMENT_INCOME] || 0}
          onChange={onChange || (() => {})}
          question={QUESTION_RETIREMENT_INCOME}
          label="ANNUAL RETIREMENT INCOME"
          type={SLIDER_MONEY}
          gaLabel="adjust-retirement-income"
          gaCategory="results"
          rounded
          sidePanel={sidePanel}
          showMax
        />
      </div>
      {isLoading && <Spinner />}
      <ErrorDialog errorMessage={hasError} clearError={updateHasError} />
    </div>
  );
};

Sliders.propTypes = {
  type: oneOf([SLIDERS_DESKTOP, SLIDERS_MOBILE]),
  sidePanel: bool,
};

Sliders.defaultProps = {
  type: SLIDERS_MOBILE,
  sidePanel: false,
};

export default memo(Sliders);
