import { createImage, fileToBase64ImageString } from './file-input';

/**
 * Fit an image onto the canvas a la `background-size: cover;`
 * @param {HTMLCanvasElement} canvas
 * @param {HTMLImageElement} img
 * @param {number} [x=0]
 * @param {number} [y=0]
 * @param {number} [w=canvas.width]
 * @param {number} [h=canvas.height]
 * @param {number} [offsetX=0.5]
 * @param {number} [offsetY=0.5]
 */
export function fitImageOnCanvas(
  canvas,
  img,
  x = 0,
  y = 0,
  w = canvas.width,
  h = canvas.height,
  offsetX = 0.5,
  offsetY = 0.5
) {
  const ctx = canvas.getContext('2d');

  if (arguments.length === 2) {
    x = 0;
    y = 0;
    w = ctx.canvas.width;
    h = ctx.canvas.height;
  }

  // default offset is center
  // offsetX = typeof offsetX === 'number' ? offsetX : 0.5;
  // offsetY = typeof offsetY === 'number' ? offsetY : 0.5;

  // keep bounds [0.0, 1.0]
  if (offsetX < 0) offsetX = 0;
  if (offsetY < 0) offsetY = 0;
  if (offsetX > 1) offsetX = 1;
  if (offsetY > 1) offsetY = 1;

  const iw = img.width;
  const ih = img.height;
  const r = Math.min(w / iw, h / ih);
  let nw = iw * r; // new prop. width
  let nh = ih * r; // new prop. height
  let cx;
  let cy;
  let cw;
  let ch;
  let ar = 1;

  // decide which gap to fill
  if (nw < w) ar = w / nw;
  if (Math.abs(ar - 1) < 1.0e-14 && nh < h) ar = h / nh; // updated
  nw *= ar;
  nh *= ar;

  // calc source rectangle
  cw = iw / (nw / w);
  ch = ih / (nh / h);

  cx = (iw - cw) * offsetX;
  cy = (ih - ch) * offsetY;

  // make sure source rectangle is valid
  if (cx < 0) cx = 0;
  if (cy < 0) cy = 0;
  if (cw > iw) cw = iw;
  if (ch > ih) ch = ih;

  // fill image in dest. rectangle
  ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
}

/**
 * Default options for the FaceFilter preparations.
 * @type {{width: number, type: string, height: number, quality: number}}
 */
const DEFAULT_OPTIONS = {
  width: 1024,
  height: 1024,
  type: 'image/png',
  quality: 0.92,
};

/**
 * Generate the image used by the FaceFilter.
 * @param {File} file
 * @param {HTMLCanvasElement} canvas
 * @param {Object} [options={}]
 * @return {Promise<string>}
 */
export async function generateImageForFaceFilter(file, canvas, options = {}) {
  const { width, height, type, quality } = { ...DEFAULT_OPTIONS, ...options };

  const base64ImageString = await fileToBase64ImageString(file);
  const base64Image = await createImage(base64ImageString);

  /** Resize the image. */
  fitImageOnCanvas(canvas, base64Image, 0, 0, width, height);

  return canvas.toDataURL(type, quality);
}
