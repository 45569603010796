import { func, number } from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RATIO_IMAGE_4 } from '../../../components/image/constants';
import PageLayout from '../../../components/page-layout';
import QuestionHeader from '../../../components/question-header';
import SliderQuestion from '../../../components/slider-question';
import { SLIDER_MONEY } from '../../../components/slider/constant';
import Config from '../../../config';
import { QUESTON_PENSION_POT } from '../../../constants/fields';
import { CopyContext } from '../../../context/copy';
import {
  ROUTE_QUESTIONS_SUMMARY,
  ROUTE_QUESTIONS_THREE,
} from '../../../routes';

const MIN = 0;
const MAX = 1076000;

const QuestionFour = ({ value, onChange }) => {
  const [progress, updateProgress] = useState(0);
  return (
    <CopyContext.Consumer>
      {({ questionFour }) => (
        <>
          <Helmet>
            <title>{`${Config.title} | Question 4`}</title>
          </Helmet>
          <PageLayout backLink={ROUTE_QUESTIONS_THREE}>
            <div id="question-four" className="center-inner">
              <QuestionHeader
                question={4}
                title={questionFour.title}
                imgRatio={RATIO_IMAGE_4}
                progress={progress}
              />
              <SliderQuestion
                className="flex-grow"
                toLink={ROUTE_QUESTIONS_SUMMARY}
                min={MIN}
                max={MAX}
                value={value}
                onChange={onChange}
                question={QUESTON_PENSION_POT}
                type={SLIDER_MONEY}
                copy={questionFour.copy}
                ctaCopy={questionFour.cta}
                gaLabel="saving"
                backLink={ROUTE_QUESTIONS_THREE}
                rounded
                updateProgress={updateProgress}
              />
            </div>
          </PageLayout>
        </>
      )}
    </CopyContext.Consumer>
  );
};

QuestionFour.propTypes = {
  value: number.isRequired,
  onChange: func.isRequired,
};

export default QuestionFour;
