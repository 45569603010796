import { func, number } from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RATIO_IMAGE_2 } from '../../../components/image/constants';
import PageLayout from '../../../components/page-layout';
import QuestionHeader from '../../../components/question-header';
import SliderQuestion from '../../../components/slider-question';
import { SLIDER_MONEY } from '../../../components/slider/constant';
import Config from '../../../config';
import { QUESTION_MONTHLY_CONTRIBUTION } from '../../../constants/fields';
import { CopyContext } from '../../../context/copy';
import { ROUTE_QUESTIONS_THREE, ROUTE_QUESTIONS_ONE } from '../../../routes';
import styles from '../index.module.css';

const MIN = 0;
const MAX = 1500;

const QuestionTwo = ({ value, onChange }) => {
  const [progress, updateProgress] = useState(0);
  return (
    <CopyContext.Consumer>
      {({ questionTwo }) => (
        <>
          <Helmet>
            <title>{`${Config.title} | Question 2`}</title>
          </Helmet>
          <PageLayout backLink={ROUTE_QUESTIONS_ONE}>
            <div
              id="question-two"
              className={`${styles.questionTwo} center-inner`}
            >
              <QuestionHeader
                className={styles.heading}
                question={2}
                title={questionTwo.title}
                imgRatio={RATIO_IMAGE_2}
                progress={progress}
              />
              <SliderQuestion
                className="flex-grow"
                toLink={ROUTE_QUESTIONS_THREE}
                min={MIN}
                max={MAX}
                step={5}
                value={value}
                onChange={onChange}
                question={QUESTION_MONTHLY_CONTRIBUTION}
                type={SLIDER_MONEY}
                copy={questionTwo.copy}
                ctaCopy={questionTwo.cta}
                gaLabel="contribution"
                backLink={ROUTE_QUESTIONS_ONE}
                showMax
                updateProgress={updateProgress}
              />
            </div>
          </PageLayout>
        </>
      )}
    </CopyContext.Consumer>
  );
};

QuestionTwo.propTypes = {
  value: number.isRequired,
  onChange: func.isRequired,
};

export default QuestionTwo;
