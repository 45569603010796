import { bool, func, string } from 'prop-types';
import React from 'react';

import styles from './index.module.css';

function FileUpload({accept, bodyText, className, id, onChange, required}) {
  return (
    <>
      <input
        id={id}
        type="file"
        accept={accept}
        onChange={onChange}
        required={required}
        className={styles['a11y-visually-hidden']}
        />
      <label htmlFor={id} className={`${styles['file-upload']} ${className}`}>
        <span>{bodyText}</span>
      </label>
    </>
  )
}

/**
 * @property {string} accept - {@link https://mzl.la/2ZDbbWc}
 * @property {string} className
 * @property {Function} onChange
 * @property {string} required - {@link https://mzl.la/2HA6CBH}
 */
FileUpload.propTypes = {
  accept: string,
  bodyText: string,
  className: string,
  id: string.isRequired,
  onChange: func,
  required: bool,
};

FileUpload.defaultProps = {
  accept: 'image/*',
  bodyText: 'Upload File',
  className: '',
  onChange: () => {},
  required: false,
};


export default FileUpload;
