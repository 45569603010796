import { func, bool, string } from 'prop-types';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Image from '../../../components/image';
import { RATIO_IMAGE_SUMMARY } from '../../../components/image/constants';
import PageLayout from '../../../components/page-layout';
import BackButton from '../../../components/ui/back-button';
import Config from '../../../config';
import { CopyContext } from '../../../context/copy';
import { FaceFilterContext, MODES } from '../../../context/facefilter';
import { ROUTE_QUESTIONS_FOUR } from '../../../routes';
import { requestUserMediaPermission } from '../../../utils/camera';
import GoalsImage from './assets/goals.png';
import ButtonLoader from './button-loader';
import styles from './index.module.css';

const Summary = ({ onSubmit, loadingComplete, isLoading, isError }) => {
  const { setMode, setStream } = useContext(FaceFilterContext);
  const { questionSummary } = useContext(CopyContext);

  const onClick = async () => {
    const stream = await requestUserMediaPermission();

    if (stream) {
      setStream(stream);
      setMode(MODES.VIDEO);
    } else {
      setMode(MODES.IMAGE);
    }

    onSubmit();
  };

  return (
    <>
      <Helmet>
        <title>{`${Config.title} | Summary`}</title>
      </Helmet>
      <PageLayout backLink={ROUTE_QUESTIONS_FOUR}>
        <div id="question-summary" className="center-inner">
          <div className="container flex-grow">
            <h1>{questionSummary.title}</h1>
            <div className={`${styles.image} img-container`}>
              <Image
                src={GoalsImage}
                altText="Sun chair"
                ratio={RATIO_IMAGE_SUMMARY}
                className="question-img"
              />
            </div>
          </div>
          <nav>
            <ul className={styles.ctaWrapper}>
              <li className={styles.confirmButtonWrapper}>
                <div className={styles.buttonWrapper}>
                  <div className={styles.backButtonWrapper}>
                    <BackButton backLink={ROUTE_QUESTIONS_FOUR} />
                  </div>
                  <ButtonLoader
                    onSubmit={onClick}
                    loadingComplete={loadingComplete}
                    isLoading={isLoading}
                    copy={questionSummary.cta}
                  />
                  {isError && <div className="error">{isError}</div>}
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </PageLayout>
    </>
  );
};

Summary.propTypes = {
  onSubmit: func.isRequired,
  isLoading: bool.isRequired,
  loadingComplete: bool.isRequired,
  isError: string.isRequired,
};

export default Summary;
