import getOrientedImage from 'exif-orientation-image';

/* eslint-disable unicorn/prefer-add-event-listener */


/**
 * @param {File} file
 * @return {Promise<string>}
 */
export function readFile(file) {
  
  return new Promise((resolve) => {
    const reader = new FileReader();
    
    // We use `.onload` rather than `addEventListener` for garbage collection.
    reader.onload = (event) => resolve(event.target.result);
    reader.readAsDataURL(file)
  })
}


/**
 * Rotates the image based on the "orientation" EXIF data of the given `File`.
 * @requires npm:exif-orientation-image
 * @see {@link https://www.npmjs.com/package/exif-orientation-image|Dependency}
 * @see {@link http://bit.ly/2m4phxR|StackOverflow}
 * @see {@link http://bit.ly/2m3sXjm|StackOverflow}
 * @param {File} file
 * @return {Promise<string>} - Base64 string of the new image.
 */
export function fixImageFileOrientation(file) {
  return new Promise((resolve, reject) => {
    getOrientedImage(file, (error, canvas) => {
      if (error) {
        reject(error);
        return;
      }
      
      resolve(canvas.toDataURL());
    });
  });
}


/**
 * Take a `File` (e.g from a `input[type=file]`), and convert it to a base64
 *    image.
 * @param {File} file
 * @return {Promise<string>}
 */
export function fileToBase64ImageString(file) {
  return fixImageFileOrientation(file);
}

/**
 * Utility method, I did not know where to put this.
 * @param {string} src
 * @return {Promise<HTMLImageElement>}
 */
export function createImage(src) {
  return new Promise((resolve) => {
    const image = new Image();
    // We use `.onload` rather than `addEventListener` for garbage collection.
    image.onload = () => resolve(image);
    image.src = src;
  })
}


/**
 * @param {File.size|number} fileSizeInBytes
 * @return {number} - The filesize in megabytes.
 */
export function getFileSizeInMegabytes(fileSizeInBytes) {
  return fileSizeInBytes / 1024 / 1024;
}


/**
 * Whether the fileSize is smaller than x MB.
 * @param {File.size|number} fileSizeInBytes
 * @param {number} [maxFileSizeInMB=15]
 * @return {boolean}
 */
export function validateFileSize(fileSizeInBytes, maxFileSizeInMB = 15) {
  return getFileSizeInMegabytes(fileSizeInBytes) < maxFileSizeInMB;
}

/* eslint-enable unicorn/prefer-add-event-listener */