import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  RATIO_SQUARE,
  RATIO_LOGO,
  RATIO_FULL_WIDTH,
  RATIO_IMAGE_1,
  RATIO_IMAGE_2,
  RATIO_IMAGE_3,
  RATIO_IMAGE_4,
  RATIO_IMAGE_SUMMARY,
  RATIO_FULL_HEIGHT,
} from './constants';
import styles from './index.module.css';

class ImageContainer extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    fit: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    ratio: PropTypes.oneOf([
      RATIO_SQUARE,
      RATIO_LOGO,
      RATIO_FULL_WIDTH,
      RATIO_IMAGE_1,
      RATIO_IMAGE_2,
      RATIO_IMAGE_3,
      RATIO_IMAGE_4,
      RATIO_IMAGE_SUMMARY,
      RATIO_FULL_HEIGHT,
    ]),
    rootmargin: PropTypes.string,
    threshold: PropTypes.number,
    altText: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    fit: false,
    lazyLoad: true,
    ratio: undefined,
    rootmargin: '50px 0px',
    threshold: 0,
    altText: 'Scottish Widow',
  };

  state = {
    visible: !this.props.lazyLoad, //eslint-disable-line
  };

  componentDidMount() {
    this.attachObserver();
  }

  componentWillUnmount() {
    if (this.#observer && this.imgElement) {
      this.#observer.unobserve(this.imgElement);
      this.#observer = null;
    }

    if (this.#img) {
      this.#img.removeEventListener('load', this.loadImage);
      this.#img = null;
    }
  }

  onIntersection = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting && this.#observer) {
        this.#observer.unobserve(entry.target);
        this.#observer = null;
        this.preloadImage();
      }
    });
  };

  loadImage = () => {
    this.#img.removeEventListener('load', this.loadImage);
    this.#img = null;
    this.setState({ visible: true });
  };

  preloadImage() {
    const { src } = this.props;
    this.#img = document.createElement('img');
    this.#img.addEventListener('load', this.loadImage);
    this.#img.src = src;
  }

  #observer;

  #img;

  attachObserver() {
    const { lazyLoad, rootmargin, threshold } = this.props;
    if (lazyLoad) {
      const config = {
        rootmargin,
        threshold,
      };

      this.#observer = new IntersectionObserver(this.onIntersection, config);
      this.#observer.observe(this.imgElement);
    }
  }

  render() {
    const {
      altText,
      fit,
      lazyLoad,
      ratio,
      rootmargin,
      src,
      threshold,
      className,
      ...other
    } = this.props;

    const { visible } = this.state;

    return (
      <picture
        className={ClassNames(
          {
            [styles.root]: ratio,
            [styles[ratio]]: ratio && styles[ratio],
            [styles.lazy]: !lazyLoad,
          },
          className
        )}
        style={lazyLoad && visible ? { opacity: 1 } : {}}
      >
        <img
          alt={altText}
          ref={e => {
            this.imgElement = e;
          }}
          src={!lazyLoad || visible ? src : null}
          draggable={false}
          {...other}
        />
      </picture>
    );
  }
}

export default ImageContainer;
