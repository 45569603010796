const ICON_SOCIAL = 'social';
const ICON_INFO = 'info';
const ICON_CAMERA = 'camera';
const ICON_COPY_TO_CLIPBOARD = 'copy-to-clipboard';
const ICON_CLOSE = 'close';
const ICON_ERROR = 'error';
const ICON_BACK_ARROW = 'back-arrow';
const ICON_CLOSE_SMALL = 'close-small';
const ICON_RESET = 'reset';
const ICON_PRINT = 'print';
const ICON_DOWNLOAD = 'download';

const ICON_SMALL = 'small';
const ICON_LARGE = 'large';

const ICON_COLOR_BLACK = 'black';
const ICON_COLOR_WHITE = 'white';

export {
  ICON_SOCIAL,
  ICON_SMALL,
  ICON_LARGE,
  ICON_INFO,
  ICON_COLOR_BLACK,
  ICON_COLOR_WHITE,
  ICON_CLOSE,
  ICON_CAMERA,
  ICON_ERROR,
  ICON_BACK_ARROW,
  ICON_CLOSE_SMALL,
  ICON_RESET,
  ICON_PRINT,
  ICON_COPY_TO_CLIPBOARD,
  ICON_DOWNLOAD,
};
