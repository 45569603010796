/**
 * @fileoverview
 * We are initialising the `scottishwidow-faceaging` custom element with a
 *    video or an image based on the `mode` prop passed to this component.
 * Video flow:
 *    1. Video with `preload=none` (we are using metadata as our callback).
 *    2. Wait for custom element to be fully loaded/initialised
 *    2. Request user media stream, play the video.
 *    3. Wait for the `loadedmetadata` event, we're good to go!
 * Image flow:
 *    1. Load image from base64 string (this.props.imageSource)
 *    2. Once loaded, listen for the `faceenter` event from the custom-element.
 *    3. If we got a face, we're good to go!
 *    4. If no face was found after {FaceAging.imageDetectionTimeoutDuration},
 *        cancel everything.
 */

import { func, number, string, object } from 'prop-types';
import React, { PureComponent } from 'react';
import { MODES } from '../../../context/facefilter';
import { requestStream, stopStream } from '../../../utils/camera';

class FaceAging extends PureComponent {
  state = {
    webGLLoaded: false,
  };
  
  constructor(props) {
    super(props);

    /**
     * The video element we stream the camera to.
     * @type {?HTMLVideoElement}
     */
    this.video = null;

    /**
     * The user media stream.
     * @type {?MediaStream}
     */
    this.stream = null;

    /** @see FaceAging.onImageReady */
    this.imageDetectionTimeout = null;
    this.imageDetectionTimeoutDuration = 10000;
  }

  componentDidMount() {
    this.element.addEventListener('load', this.onLoad);
    this.element.addEventListener('error', this.onError);

    // Add attributes to video element to ensure autoplay.
    this.video.setAttribute('playsinline', 'true');
    this.video.setAttribute('controls', 'true');
    /** @see {@link https://github.com/facebook/react/issues/10389} */
    this.video.setAttribute('muted', 'true');
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.webGLLoaded) {
      const changedMode = prevProps.mode !== this.props.mode;
      const changedImage = prevProps.imageSource !== this.props.imageSource;
      
      /**
       * Update media input if:
       * - The MODE has changed (FaceFilterContext.state.mode)
       * - The imageSrc has changed (New image uploaded)
       * - The WebGL Component changed its 'loaded' state.
       */
      if (changedMode || changedImage || this.state.webGLLoaded !== prevState.webGLLoaded) {
        if (this.props.mode === MODES.IMAGE && this.props.imageSource) {
          this.initialiseImage();
        } else if (this.props.mode === MODES.VIDEO) {
          this.initialiseVideo();
        }
      }
    }
  }

  componentWillUnmount() {
    this.video.removeEventListener('loadedmetadata', this.onVideoReady);
    clearTimeout(this.imageDetectionTimeout);
    this.stopVideo();
    this.removeInput();
  }

  // FaceAging Custom-Element API.

  /** Triggers when the custom element is fully initialised. */
  onLoad = () => {
    this.setState({ webGLLoaded: true });
  };

  /** Triggers when the custom-element fires an "error" event. */
  onError = error => {
    this.props.onError(error);
  };

  // Image API.

  /**
   * Initialise the image path.
   */
  initialiseImage = () => {
    this.image.src = '';
    this.image.addEventListener('load', this.onImageReady);
    this.image.src = this.props.imageSource;
  };

  /** Once the image is loaded, pass it to the custom element. */
  onImageReady = () => {
    // * DETECT IF SAME IMAGE IS PASSED THROUGH
    if (this.element.input) {
      if (this.element.input.src === this.props.imageSource) {
        this.inputIsReady();
        return;
      }
    }

    this.element.addEventListener('faceenter', this.foundFaceInImage);
    this.element.input = this.image;

    this.imageDetectionTimeout = setTimeout(() => {
      this.removeInput();
      this.props.onImageDetectionTimedOut();
    }, this.imageDetectionTimeoutDuration);
  };

  /** When the custom element found a face, stop the rendering loop. */
  foundFaceInImage = () => {
    this.element.removeEventListener('faceenter', this.foundFaceInImage);
    clearTimeout(this.imageDetectionTimeout);

    this.inputIsReady();
  };

  // Video API.

  /**
   * Initialise video mode.
   */
  initialiseVideo = async () => {
    await this.startVideo();
  };

  /** Start streaming from user media. */
  startVideo = async () => {
    if (this.stream && this.stream.active && this.video.srcObject) return;

    this.video.addEventListener('loadedmetadata', this.onVideoReady);

    // Request a stream. Catches an error if the user denies camera permission.
    try {
      // If we have a stream that is ACTIVE, use that one.
      // Otherwise, request a new stream.
      if (this.props.stream && this.props.stream.active) {
        this.stream = this.props.stream;
      } else {
        this.stream = await requestStream();
      }

      this.video.srcObject = this.stream;
      this.video.removeAttribute('controls');
      this.element.input = this.video;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
      this.props.onPermissionDenied();
    }
  };

  /** Reset the video element. */
  stopVideo = () => {
    this.video.pause();
    this.video.srcObject = null;

    if (this.stream) {
      stopStream(this.stream);
    }
  };

  /** Triggers when the video is actively playing a stream. */
  onVideoReady = async () => {
    this.video.removeEventListener('loadedmetadata', this.onVideoReady);

    try {
      await this.video.play();
      this.inputIsReady();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

  // Shared functionality (image/video).

  /**
   * Triggers when either the video or the image input is initialised.
   */
  inputIsReady = () => {
    this.element.age = this.props.age;

    this.props.onGotInput(true);
  };

  /**
   * Remove the input from the custom element.
   */
  removeInput = () => {
    this.element.input = null;
    this.props.onGotInput(false);
  };

  render() {
    return (
      <>
        <scottishwidows-faceaging
          baseURI="webgl/"
          mirror={this.props.mode === MODES.VIDEO}
          ref={element => (this.element = element)}
        />
        <video
          muted
          playsInline
          preload="none"
          aria-hidden="true"
          hidden
          ref={element => (this.video = element)}
          autoPlay
        />
        <img
          alt="It's you!"
          aria-hidden="true"
          hidden
          ref={element => (this.image = element)}
        />
      </>
    );
  }
}

/**
 * @type {Object}
 * @property {number} age - The age that should be represented in the graphics.
 * @property {Function} errorCallback - Catch all for when it errors.
 * @property {string} type - {@see MODES}
 */
FaceAging.propTypes = {
  age: number,
  imageSource: string,
  mode: string.isRequired,
  stream: object,

  onError: func,
  onGotInput: func,
  onPermissionDenied: func,
  onImageDetectionTimedOut: func,
};

FaceAging.defaultProps = {
  age: 50,
  onError: () => {},
  onGotInput: () => {},
  onPermissionDenied: () => {},
  onImageDetectionTimedOut: () => {},
};

export default FaceAging;
