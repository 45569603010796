import {
  string,
  shape,
  oneOfType,
  array,
  bool,
  number,
  object,
} from 'prop-types';

export default shape({
  hash: string.isRequired,
  key: string, // only in createBrowserHistory and createMemoryHistory
  pathname: string.isRequired,
  search: string.isRequired,
  state: oneOfType([array, bool, number, object, string]), // only in createBrowserHistory and createMemoryHistory
});
