import { shape, oneOf, func, arrayOf, number } from 'prop-types';
import location from './location';

export default shape({
  action: oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: func.isRequired,
  canGo: func, // only in createMemoryHistory
  createHref: func.isRequired,
  entries: arrayOf(location), // only in createMemoryHistory
  go: func.isRequired,
  goBack: func.isRequired,
  goForward: func.isRequired,
  index: number, // only in createMemoryHistory
  length: number,
  listen: func.isRequired,
  location: location.isRequired,
  push: func.isRequired,
  replace: func.isRequired,
});
