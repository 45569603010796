import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import PageLayout from '../../components/page-layout';
import Config from '../../config';
import { CopyContext } from '../../context/copy';
import styles from './index.module.css';

const AboutPage = () => (
  <CopyContext.Consumer>
    {({ about }) => (
      <>
        <Helmet>
          <title>{`${Config.title} | About`}</title>
        </Helmet>
        <PageLayout hideSocial>
          <div className="center-inner">
            <div className="container">
              <h2 className={styles.title}>{about.title}</h2>
              <div
                id="about-copy"
                className={styles.copy}
                dangerouslySetInnerHTML={{ __html: about.copy }}
              />
            </div>
          </div>
        </PageLayout>
      </>
    )}
  </CopyContext.Consumer>
);

export default memo(AboutPage);
