import Classnames from 'classnames';
import { bool, func, string } from 'prop-types';
import React, { memo } from 'react';
import styles from './index.module.css';

const ButtonLoader = ({ isLoading, loadingComplete, onSubmit, copy }) => (
  <button
    id="summary-submit"
    className={Classnames(
      'submit',
      'button',
      'button-primary',
      'center',
      'main-cta',
      styles.root
    )}
    disabled={isLoading}
    type="button"
    onClick={() => {
      onSubmit();
    }}
  >
    <span>{copy}</span>
    <div
      className={Classnames(styles.loadingWrapper, {
        [styles.active]: isLoading || loadingComplete,
        [styles.loading]: isLoading && !loadingComplete,
        [styles.complete]: loadingComplete,
      })}
    >
      <div className={styles.bar} />
      <div className={styles.shadow} />
    </div>
  </button>
);

ButtonLoader.propTypes = {
  isLoading: bool.isRequired,
  loadingComplete: bool.isRequired,
  onSubmit: func.isRequired,
  copy: string.isRequired,
};

export default memo(ButtonLoader);
