const formatNumber = number => {
  if (typeof Intl !== 'object') {
    return `£${number}`;
  }

  return number == 0
    ? `£0`
    : `£${new Intl.NumberFormat('en-GB', {
        maximumSignificantDigits: 3,
      }).format(number)}`;
};

export { formatNumber };
