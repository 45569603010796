import Classnames from 'classnames';
import { oneOf, string } from 'prop-types';
import React from 'react';
import InlineSVG from 'svg-inline-react';
import * as ICON from './constant';
import styles from './index.module.css';

import BackArrow from './svg/back-arrow.svg';
import Camera from './svg/camera.svg';
import CloseSmall from './svg/close_small.svg';
import Close from './svg/close.svg';
import Download from './svg/download.svg';
import Error from './svg/error.svg';
import Info from './svg/info.svg';
import CopyToClipboard from './svg/link.svg';
import Print from './svg/print.svg';
import Reset from './svg/reset.svg';
import Share from './svg/social.svg';

const mapping = {
  [ICON.ICON_SOCIAL]: Share,
  [ICON.ICON_INFO]: Info,
  [ICON.ICON_CAMERA]: Camera,
  [ICON.ICON_CLOSE]: Close,
  [ICON.ICON_ERROR]: Error,
  [ICON.ICON_BACK_ARROW]: BackArrow,
  [ICON.ICON_CLOSE_SMALL]: CloseSmall,
  [ICON.ICON_RESET]: Reset,
  [ICON.ICON_PRINT]: Print,
  [ICON.ICON_COPY_TO_CLIPBOARD]: CopyToClipboard,
  [ICON.ICON_DOWNLOAD]: Download,
};

const Icon = ({ type, size, color, className }) => (
  <InlineSVG
    src={mapping[type]}
    className={Classnames(
      styles.icon,
      styles[type],
      styles[size],
      [color],
      [type],
      className
    )}
  />
);

Icon.propTypes = {
  type: oneOf([
    ICON.ICON_SOCIAL,
    ICON.ICON_INFO,
    ICON.ICON_CAMERA,
    ICON.ICON_CLOSE,
    ICON.ICON_ERROR,
    ICON.ICON_BACK_ARROW,
    ICON.ICON_CLOSE_SMALL,
    ICON.ICON_RESET,
    ICON.ICON_PRINT,
    ICON.ICON_COPY_TO_CLIPBOARD,
    ICON.ICON_DOWNLOAD,
  ]),
  color: oneOf([ICON.ICON_COLOR_WHITE, ICON.ICON_COLOR_BLACK]),
  size: oneOf([ICON.ICON_SMALL, ICON.ICON_LARGE]),
  className: string,
};

Icon.defaultProps = {
  color: ICON.ICON_COLOR_BLACK,
  className: '',
};

export default Icon;
