import { string, func, number, oneOf, bool } from 'prop-types';
import React from 'react';
import Slider from '../slider';
import { SLIDER_MONEY, SLIDER_AGE } from '../slider/constant';
import BackButton from '../ui/back-button';
import ConfirmButton from './confirm-button';

import styles from './index.module.css';

const SliderQuestion = ({
  toLink,
  min,
  max,
  step,
  value,
  onChange,
  question,
  type,
  avg,
  copy,
  className,
  ctaCopy,
  gaLabel,
  rounded,
  backLink,
  showMax,
  updateProgress,
}) => (
  <>
    <div className={className}>
      <div className={styles.sliderWrapper}>
        <Slider
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={onChange}
          question={question}
          type={type}
          avg={avg}
          gaLabel={gaLabel}
          gaCategory="questions"
          rounded={rounded}
          showMax={showMax}
          updateProgress={updateProgress}
        />
      </div>
      {copy && copy !== 'EMPTY' && (
        <div className="sub-copy" dangerouslySetInnerHTML={{ __html: copy }} />
      )}
    </div>
    <nav>
      <ul className={styles.ctaWrapper}>
        {backLink && (
          <li className={styles.backButtonWrapper}>
            <BackButton backLink={backLink} />
          </li>
        )}
        <li className={styles.confirmButtonWrapper}>
          <ConfirmButton
            toLink={toLink}
            question={question}
            ctaCopy={ctaCopy}
            gaLabel={gaLabel}
          />
        </li>
      </ul>
    </nav>
  </>
);

SliderQuestion.propTypes = {
  toLink: string.isRequired,
  min: number.isRequired,
  max: number.isRequired,
  step: number,
  value: number.isRequired,
  onChange: func.isRequired,
  question: string.isRequired,
  type: oneOf([SLIDER_MONEY, SLIDER_AGE]),
  className: string,
  avg: number,
  copy: string,
  ctaCopy: string,
  gaLabel: string.isRequired,
  rounded: bool,
  backLink: string,
  showMax: bool,
  updateProgress: func.isRequired,
};

SliderQuestion.defaultProps = {
  type: SLIDER_AGE,
  avg: null,
  copy: '',
  className: '',
  ctaCopy: 'Next',
  rounded: false,
  showMax: false,
};

export default SliderQuestion;
