import { func, number } from 'prop-types';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RATIO_IMAGE_3 } from '../../../components/image/constants';
import PageLayout from '../../../components/page-layout';
import QuestionHeader from '../../../components/question-header';
import SliderQuestion from '../../../components/slider-question';
import { SLIDER_MONEY } from '../../../components/slider/constant';
import Config from '../../../config';
import { QUESTION_RETIREMENT_INCOME } from '../../../constants/fields';
import { CopyContext } from '../../../context/copy';
import { ROUTE_QUESTIONS_FOUR, ROUTE_QUESTIONS_TWO } from '../../../routes';
import styles from '../index.module.css';

const MIN = 5000;
const MAX = 100000;

const QuestionThree = ({ value, onChange }) => {
  const [progress, updateProgress] = useState(0);
  return (
    <CopyContext.Consumer>
      {({ questionThree }) => (
        <>
          <Helmet>
            <title>{`${Config.title} | Question 3`}</title>
          </Helmet>
          <PageLayout backLink={ROUTE_QUESTIONS_TWO}>
            <div
              id="question-three"
              className={`${styles.questionThree} center-inner`}
            >
              <QuestionHeader
                className={styles.heading}
                question={3}
                title={questionThree.title}
                imgRatio={RATIO_IMAGE_3}
                progress={progress}
              />
              <SliderQuestion
                className="flex-grow"
                toLink={ROUTE_QUESTIONS_FOUR}
                min={MIN}
                max={MAX}
                value={value}
                onChange={onChange}
                question={QUESTION_RETIREMENT_INCOME}
                type={SLIDER_MONEY}
                copy={questionThree.copy}
                ctaCopy={questionThree.cta}
                gaLabel="income"
                backLink={ROUTE_QUESTIONS_TWO}
                rounded
                showMax
                updateProgress={updateProgress}
              />
            </div>
          </PageLayout>
        </>
      )}
    </CopyContext.Consumer>
  );
};

QuestionThree.propTypes = {
  value: number.isRequired,
  onChange: func.isRequired,
};

export default QuestionThree;
