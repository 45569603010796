import ClassNames from 'classnames';
import { string } from 'prop-types';
import React from 'react';
import {
  QUESTON_PENSION_POT,
  QUESTION_MONTHLY_CONTRIBUTION,
} from '../../../../constants/fields';
import { AppContext } from '../../../../context/app';
import { CopyContext } from '../../../../context/copy';
import styles from './index.module.css';

const CopyWrapper = ({ copy, secondaryLine }) => (
  <div className={ClassNames(styles.root, 'see-panel')}>
    <div className="copy-text" dangerouslySetInnerHTML={{ __html: copy }} />
    <div
      className="copy-secondary-line"
      dangerouslySetInnerHTML={{ __html: secondaryLine }}
    />
  </div>
);

CopyWrapper.propTypes = {
  copy: string.isRequired,
  secondaryLine: string.isRequired,
};

const OLD = 'Over 100';
const YOUNG = 55;

const Copy = () => (
  <CopyContext.Consumer>
    {({
      results: {
        copy_a,
        copy_b,
        copy_c,
        copy_d,
        slider_line_desktop,
        slider_line_mobile,
      },
    }) => (
      <AppContext.Consumer>
        {({ results, questions }) => {
          if (Object.keys(results).length === 0) return null;

          const { current_retirement_prediction } = results;

          // mobile view - min-width: 1025px
          const { matches } = window.matchMedia('(min-width: 1025px)');

          const secondaryLine = matches
            ? slider_line_desktop
            : slider_line_mobile;

          if (
            questions[QUESTON_PENSION_POT] === 0 &&
            questions[QUESTION_MONTHLY_CONTRIBUTION] === 0
          ) {
            return <CopyWrapper copy={copy_a} secondaryLine={secondaryLine} />;
          }

          if (
            current_retirement_prediction === OLD &&
            questions[QUESTON_PENSION_POT] >= 1 &&
            questions[QUESTION_MONTHLY_CONTRIBUTION] >= 0
          ) {
            return <CopyWrapper copy={copy_b} secondaryLine={secondaryLine} />;
          }

          if (current_retirement_prediction <= YOUNG) {
            return (
              <CopyWrapper
                copy={copy_d.replace('AGE', current_retirement_prediction)}
                secondaryLine={secondaryLine}
              />
            );
          }

          return (
            <CopyWrapper
              copy={copy_c.replace('AGE', current_retirement_prediction)}
              secondaryLine={secondaryLine}
            />
          );
        }}
      </AppContext.Consumer>
    )}
  </CopyContext.Consumer>
);

export default Copy;
