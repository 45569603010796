/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Classnames from 'classnames';
import { string, func, number, oneOf, bool } from 'prop-types';
import React, { PureComponent } from 'react';
import { Transition } from 'react-transition-group';
import { formatNumber } from '../../utils';
import { SLIDER_MONEY, SLIDER_AGE } from './constant';
import styles from './index.module.css';

const DIRECTION_RIGHT = 'right';
const DIRECTION_LEFT = 'left';

const LEFT_ARROW = 'ArrowLeft';
const RIGHT_ARROW = 'ArrowRight';
/** */
const KEYDOWN_MOVE_DISTANCE = 10;

class Slider extends PureComponent {
  static propTypes = {
    min: number.isRequired,
    max: number.isRequired,
    step: number,
    value: number.isRequired,
    onChange: func.isRequired,
    question: string.isRequired,
    label: string,
    type: oneOf([SLIDER_MONEY, SLIDER_AGE]),
    avg: number,
    gaCategory: string.isRequired,
    rounded: bool,
    sidePanel: bool,
    showMax: bool,
    updateProgress: func,
  };

  static defaultProps = {
    label: '',
    type: SLIDER_AGE,
    avg: null,
    bool: false,
    sidePanel: false,
    showMax: false,
    updateProgress: null,
  };

  state = {
    onHold: false,
  };

  #bar;
  #handle;
  #avgContainer;
  #startPos;
  #isActive;
  #currentPos;
  #px;
  #currentWidth;
  #handleWrapper;
  #oldx;
  #direction;
  #prevMove = 0;

  onMouseDown = event => {
    this.#startPos = event.touches ? event.touches[0].screenX : event.screenX;
    this.#isActive = true;
    this.setState({ onHold: true });
  };

  roundToThousand = value => {
    if (value < 999) {
      return value;
    }
    return Math.round(value / 1000) * 1000;
  };

  /**
   * @param {number} value
   * @return {number}
   */
  roundToStep = value => {
    return Math.ceil(value / this.props.step) * this.props.step;
  };

  calculatePercent = (px, width) => ((px + 11) / width) * 100;

  calculateValue = (px, width) => {
    const offSet = this.#handle.offsetWidth / 2;
    const { max, min, step, question, onChange, rounded } = this.props;
    const c = px / ((width - offSet) / (max - min));

    const { updateProgress } = this.props;

    let value;

    if (px < 0) {
      value = min;
    } else if (px > this.#bar.offsetWidth - offSet) {
      value = max;
    } else {
      value = Math.round(min + c);
    }

    if (step) {
      value = this.roundToStep(value);
    }

    if (rounded) {
      onChange(question, this.roundToThousand(value));
    } else {
      onChange(question, value);
    }

    if (typeof updateProgress == 'function') {
      updateProgress(this.calculatePercent(px, width));
    }
  };

  rotateHandle = deg => {
    if (deg > 0 && deg < 35) {
      this.#handleWrapper.style.transform = `rotate(${-Math.abs(deg)}deg)`;
    } else if (deg < 0 && deg > -35) {
      this.#handleWrapper.style.transform = `rotate(${Math.abs(deg)}deg)`;
    }
  };

  moveDirection = (pageX, move) => {
    if (pageX < this.oldx) {
      if (this.#direction == DIRECTION_RIGHT) {
        this.#prevMove = move;
      }
      this.#direction = DIRECTION_LEFT;
    } else if (pageX > this.oldx) {
      if (this.#direction == DIRECTION_LEFT) {
        this.#prevMove = move;
      }
      this.#direction = DIRECTION_RIGHT;
    }
    this.rotateHandle(move - this.#prevMove);
    this.oldx = pageX;
  };

  mouseMove = event => {
    if (this.#isActive) {
      const screenX = event.touches ? event.touches[0].screenX : event.screenX;
      const pageX = event.touches ? event.touches[0].pageX : event.pageX;
      const move = screenX - this.#startPos;

      const movePixel = this.keepHandleInBounds(this.#currentPos + move);

      if (this.#handle && this.#bar) {
        this.moveElements(movePixel, this.#currentWidth);
        this.calculateValue(movePixel, this.#currentWidth);
        this.#px = movePixel;
        this.moveDirection(pageX, move);
      }
    }
  };

  mouseUp = () => {
    if (this.#isActive) {
      this.#isActive = false;
      this.setState({ onHold: false });
      if (this.#px) {
        this.#currentPos = this.#px;
      }
    }
  };

  /** @param {KeyboardEvent.key|string} key */
  handleKeyDown = ({ key }) => {
    let moveDistance = null;

    if (key === LEFT_ARROW) {
      moveDistance = -KEYDOWN_MOVE_DISTANCE;
    } else if (key === RIGHT_ARROW) {
      moveDistance = KEYDOWN_MOVE_DISTANCE;
    }

    if (moveDistance) {
      this.#currentPos = this.keepHandleInBounds(
        this.#currentPos + moveDistance
      );

      this.calculateValue(this.#currentPos, this.#currentWidth);
      this.moveElements(this.#currentPos, this.#currentWidth);
    }
  };

  /**
   * Make sure the handle stays within the slider's bounds.
   * @param {number} newPosition
   * @return {number} - The new position, inside the given bounds.
   */
  keepHandleInBounds = newPosition => {
    this.#currentWidth = this.#bar.offsetWidth;

    const offset = this.#handle.offsetWidth / 4;
    const endX = this.#currentWidth - offset;
    // Assigning it to a new reference to prevent unwanted side effects.
    let position = 0;

    if (typeof newPosition === 'number') {
      position = newPosition;
    } else {
      position = this.#currentWidth;
    }

    // Make sure the handle stays within the bounds.
    if (newPosition > endX) {
      position = endX;
    } else if (newPosition < -Math.abs(offset)) {
      position = -Math.abs(offset);
    }

    return position;
  };

  moveElements = move => {
    if (this.#handle && this.#bar) {
      this.#handle.style.transform = `translate3d(${move}px,0,0)`;
      this.#bar.style.transform = `translate3d(${move}px,0,0)`;
    }
  };

  getPosition = value => {
    if (this.#bar) {
      const { max, min } = this.props;

      // Make sure the value is not higher than the max value authorized
      if (value > max) value = max;
      return (this.#bar.offsetWidth / (max - min)) * (value - min);
    }
  };

  setAvgPosition = () => {
    const { avg } = this.props;
    if (this.#avgContainer) {
      this.#avgContainer.style.transform = `translate3d(${this.getPosition(
        avg
      ) -
        this.#handle.offsetWidth / 4}px,0,0)`;
    }
  };

  setHandler = () => {
    const { value } = this.props;
    this.moveElements(this.getPosition(value));
  };

  setIntialPostion = () => {
    if (this.#bar) {
      const { value } = this.props;
      this.#currentWidth = this.#bar.offsetWidth;
      this.#currentPos = this.getPosition(value);
      this.setHandler();
    }
    this.setAvgPosition();
  };

  reszize = () => {
    this.#currentWidth = this.#bar.offsetWidth;
    if (this.#handle && this.#bar && this.#currentWidth) {
      this.setIntialPostion();
    }
  };

  onMouseDownBar = event => {
    const { sidePanel } = this.props;
    const width = sidePanel ? 400 : window.innerWidth;
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const b = (width - this.#bar.offsetWidth) / 2;
    const px = clientX - b - this.#handle.offsetWidth / 4;
    this.moveElements(px, this.#currentWidth);
    this.calculateValue(px, this.#currentWidth);
    this.#currentPos = px;
  };

  componentDidMount() {
    this.setIntialPostion();
    window.addEventListener('touchmove', this.mouseMove, false);
    window.addEventListener('touchend', this.mouseUp, false);
    window.addEventListener('mousemove', this.mouseMove, false);
    window.addEventListener('mouseup', this.mouseUp, false);
    window.addEventListener('resize', this.reszize, false);
    this.#handle.addEventListener('keydown', this.handleKeyDown, false);

    const { updateProgress } = this.props;

    if (this.#handle && this.#bar) {
      let movePixel = this.keepHandleInBounds(this.#currentPos);

      if (movePixel === 0) {
        movePixel = -(this.#handle.offsetWidth / 4);
      }

      if (typeof updateProgress === 'function') {
        updateProgress(this.calculatePercent(movePixel, this.#currentWidth));
      }

      this.moveElements(movePixel, this.#currentWidth);
      this.#px = movePixel;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.mouseMove);
    window.removeEventListener('touchend', this.mouseUp);
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.mouseUp);
    window.removeEventListener('resize', this.reszize);
    this.#handle.removeEventListener('keydown', this.handleKeyDown);
  }

  formattedValue = () => {
    const { type, max, showMax } = this.props;
    const { value } = this.props;

    if (type === SLIDER_AGE) {
      if (showMax && value == max) {
        return `${value}+`;
      }
      return value;
    } else {
      let n;
      if (value > 999999) {
        n = `£${(value / 1000000).toFixed(3).replace(/\.0$/, '')}M`;
      } else if (value > 2000) {
        n = `£${(value / 1000).toFixed(1).replace(/\.0$/, '')}K`;
      } else {
        n = formatNumber(value).replace(',000', 'K');
      }

      if (showMax && value == max) {
        return `${n}+`;
      }
      return n;
    }
  };

  render() {
    const { label, avg, gaCategory } = this.props;
    const { onHold } = this.state;

    return (
      <Transition
        in={onHold}
        timeout={{
          appear: 0,
          enter: 0,
          exit: 250,
        }}
        onExited={() => {
          this.#handleWrapper.style.transform = '';
        }}
      >
        {state => (
          <div
            className={Classnames(styles.root, 'slider', {
              [styles.entered]: state === 'entered',
              [styles.exiting]: state === 'exiting',
              [styles.exited]: state === 'exited',
              [styles.padding]: gaCategory !== 'questions',
            })}
          >
            <div className={styles.wrapper}>
              {label && (
                <div className={styles.titleContainer}>
                  <span>{label}</span>
                </div>
              )}
              <div className={styles.barWrapper}>
                <div
                  className={styles.bar}
                  onMouseDown={this.onMouseDownBar}
                  onTouchStart={this.onMouseDownBar}
                >
                  <div
                    className={styles.progress}
                    ref={element => {
                      this.#bar = element;
                    }}
                  />
                  <div className={styles.barShadow} />
                </div>
                <button
                  className={styles.handle}
                  ref={element => {
                    this.#handle = element;
                  }}
                  onMouseDown={this.onMouseDown}
                  onTouchStart={this.onMouseDown}
                  aria-label={this.formattedValue()}
                >
                  <div
                    className={styles.handleWrapper}
                    ref={element => {
                      this.#handleWrapper = element;
                    }}
                  >
                    <div className={styles.indicator}>
                      <span>{this.formattedValue()}</span>
                    </div>
                    <div className={styles.handleInner} />
                  </div>
                  <span className={styles.value}>{this.formattedValue()}</span>
                </button>
                {avg && (
                  <div
                    ref={element => {
                      this.#avgContainer = element;
                    }}
                    className={styles.avg}
                  >
                    <span>AVG</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Transition>
    );
  }
}

export default Slider;
