import React from 'react';
import { Link } from 'react-router-dom';
import { CopyContext } from '../../../../context/copy';
import { ROUTE_PLAN_YOUR_FUTURE } from '../../../../routes';

const ControlPanelLink = () => (
  <CopyContext.Consumer>
    {({ questionResults: { cta3 } }) => (
      <Link
        to={ROUTE_PLAN_YOUR_FUTURE}
        title="goals"
        className="button button-primary center"
      >
        {cta3}
      </Link>
    )}
  </CopyContext.Consumer>
);

export default ControlPanelLink;
