import * as Sentry from '@sentry/browser';
import React, { memo, PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import Icon from '../../components/icon';
import {
  ICON_BACK_ARROW,
  ICON_COLOR_WHITE,
} from '../../components/icon/constant';
import LoadingScreen from '../../components/loading-screen';
import BackButton from '../../components/ui/back-button';
import Config from '../../config';
import { AppContext } from '../../context/app';
import {
  FaceFilterContext,
  getFaceAgingElement,
  keepGraphicsRealistic,
  MODES,
} from '../../context/facefilter';
import { HistoryPropertyTypes } from '../../prop-types';
import { ROUTE_PLAN_YOUR_FUTURE, ROUTE_QUESTIONS_SUMMARY } from '../../routes';
import PhotoUpload from '../photo-upload';
import Snapshot from '../snapshot';
import ControlPanel from './control-panel';
import CountDown from './count-down';
import FaceAging from './face-aging';
import styles from './index.module.css';
import SnapshotButton from './snapshot-button';

class Results extends PureComponent {
  static contextType = FaceFilterContext;

  static propTypes = {
    history: HistoryPropertyTypes,
  };

  state = {
    countdown: false,
    showSnapshotOverlay: false,
    showPhotoUploadOverlay: this.context.mode === MODES.IMAGE,
    showLoadingOverlay: true,
    couldNotDetectFace: false,
  };

  constructor(props, context) {
    super(props, context);

    /**
     * Reference to the photo upload overlay.
     * We use this to scroll the element to top when revealing it.
     * @type {?HTMLElement}
     */
    this.photoUploadOverlay = null;

    /**
     * Reference to the FaceAging class.
     * Used to reach some of the API without unnecessary re-renders.
     * @type {?FaceAging}
     */
    this.faceAging = null;
  }

  componentWillUnmount() {
    this.context.setUserImageSrc('');
  }

  setShowLoadingOverlay = showLoadingOverlay =>
    this.setState({ showLoadingOverlay });

  /**
   * Triggers when a photo has uploaded.
   */
  onPhotoUpload = () => {
    if (this.context.mode === MODES.IMAGE) {
      this.setState({
        showLoadingOverlay: true,
        showPhotoUploadOverlay: false,
      });
    }
  };

  /**
   * Triggers when the face filter can not find a face after x amount of time.
   */
  onImageDetectionTimedOut = () => {
    this.setState({
      couldNotDetectFace: true,
      showLoadingOverlay: true,
      showPhotoUploadOverlay: true,
    });

    this.context.setUserImageSrc('');
  };

  /** Triggers when <CountDown/> has successfully counted down to 0. */
  onCountedDown = () => {
    this.takeSnapshot();
    this.setState({ countdown: false });
  };

  /** Triggers when the snapshot CTA is clicked. */
  onSnapshotButtonClick = () => {
    if (this.context.mode === MODES.VIDEO) {
      this.setState({ countdown: true });
    } else {
      this.takeSnapshot();
    }
  };
  
  onTouchCancelled = () => {
    this.faceAging.stopVideo();
    
    setTimeout(() => {
      this.faceAging.initialiseVideo();
    }, 200);
  };

  /** Take a snapshot. */
  takeSnapshot = () => {
    try {
      const faceAgingElement = getFaceAgingElement();

      if (faceAgingElement) {
        const image = faceAgingElement.snapshot();
        this.context.setSnapshot(image);
        this.setState({ showSnapshotOverlay: true }, () => {
          if (this.context.mode === MODES.VIDEO) {
            this.setState({ showLoadingOverlay: true });
            this.faceAging.stopVideo();
          }
        });
      }
    } catch (error) {
      Sentry.captureException(error.message);
      alert('Something went wrong please try later');
    }
  };

  /** Triggers when the snapshot overlay closes. */
  hideSnapshotOverlay = () => {
    this.setState({ showSnapshotOverlay: false }, () => {
      this.context.setSnapshot('');
      this.context.setSnapshotWithPostProcessing('');
    });

    if (this.context.mode === MODES.VIDEO) {
      this.faceAging.initialiseVideo();
    }
  };

  /**
   * Mainly for separation of concerns.
   */
  backButton = () => {
    if (this.context.mode === MODES.VIDEO) {
      return (
        <div className={styles.backButtonWrapper}>
          <BackButton backLink={ROUTE_QUESTIONS_SUMMARY} />
        </div>
      );
    }

    return (
      <div className={styles.backButtonWrapper}>
        <button
          className="back-button"
          aria-label="Go back"
          onClick={() => {
            this.context.setUserImageSrc('');

            this.setState(
              {
                showPhotoUploadOverlay: true,
                showLoadingOverlay: true,
                couldNotDetectFace: false,
              },
              () => {
                this.photoUploadOverlay.scrollTop = 0;
              }
            );
          }}
        >
          <Icon type={ICON_BACK_ARROW} color={ICON_COLOR_WHITE} />
        </button>
      </div>
    );
  };

  render() {
    const {
      mode,
      stream,
      snapshot,
      snapshotWithPostProcessing,
      setSnapshotWithPostProcessing,
      userImageSrc,
    } = this.context;

    return (
      <AppContext.Consumer>
        {({ results, isRVtablet }) => {
          if (!results.hasOwnProperty('current_retirement_prediction')) {
            this.props.history.push('/');
          }

          const age = keepGraphicsRealistic(
            results.current_retirement_prediction
          );

          const showPhotoUpload =
            mode === MODES.IMAGE &&
            !userImageSrc &&
            this.state.showPhotoUploadOverlay;

          return (
            <>
              <Helmet>
                <title>{`${Config.title} | Results`}</title>
              </Helmet>

              <div className={styles.wrapper}>
                <FaceAging
                  age={age}
                  mode={mode}
                  stream={stream}
                  imageSource={userImageSrc}
                  onImageDetectionTimedOut={this.onImageDetectionTimedOut}
                  onPermissionDenied={() => {
                    this.props.history.push(ROUTE_PLAN_YOUR_FUTURE);
                  }}
                  onGotInput={gotInput => {
                    this.setState({ couldNotDetectFace: false });
                    this.setShowLoadingOverlay(!gotInput);
                  }}
                  ref={e => (this.faceAging = e)}
                />
              </div>

              <ControlPanel backButton={this.backButton()} />

              {this.backButton()}

              <SnapshotButton
                onClick={this.onSnapshotButtonClick}
                countDown={this.state.countdown}
                mode={mode}
              />

              {/* Overlays */}

              {this.state.countdown && (
                <CountDown takePicture={this.onCountedDown} />
              )}

              {this.state.showSnapshotOverlay && (
                <div className={`${styles.overlay} ${styles.snapshot}`}>
                  <Snapshot
                    isRVtablet={isRVtablet}
                    results={results}
                    snapshot={snapshot}
                    snapshotWithPostProcessing={snapshotWithPostProcessing}
                    setSnapshotWithPostProcessing={
                      setSnapshotWithPostProcessing
                    }
                    onTouchCancelled={this.onTouchCancelled}
                    onClose={this.hideSnapshotOverlay}
                  />
                </div>
              )}

              <div
                className={`${styles.overlay} ${styles.loadingScreen}`}
                aria-hidden={!this.state.showLoadingOverlay}
              >
                <LoadingScreen />
              </div>

              <div
                className={`${styles.overlay} ${styles.photoUpload}`}
                aria-hidden={!showPhotoUpload}
                ref={element => (this.photoUploadOverlay = element)}
              >
                <PhotoUpload
                  onValidFile={this.onPhotoUpload}
                  couldNotDetectFace={this.state.couldNotDetectFace}
                />
              </div>
            </>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default withRouter(memo(Results));
