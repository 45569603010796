import { bool, func } from 'prop-types';
import React, { memo } from 'react';
import { Transition } from 'react-transition-group';
import styles from './index.module.css';

const CopyLnkSuccess = ({ state, callback }) => (
  <Transition
    in={state}
    timeout={{
      enter: 0,
      exit: 270,
    }}
    onEnter={element => {
      element.style.display = 'block';
      setTimeout(() => {
        callback(false);
      }, 3000);
    }}
    onExited={element => {
      element.style.display = 'none';
    }}
  >
    {state => (
      <div className={`${styles.root} ${state == 'entered' && styles.entered}`}>
        Successfully copied link.
      </div>
    )}
  </Transition>
);

CopyLnkSuccess.propTypes = {
  state: bool.isRequired,
  callback: func.isRequired,
};

export default memo(CopyLnkSuccess);
