import React, { memo } from 'react';
import ErrorPage from '../../components/error-page';
import Config from '../../config';
import { CopyContext } from '../../context/copy';

const BroswerSupport = () => (
  <CopyContext.Consumer>
    {({ broswerSupport }) => (
      <ErrorPage
        pageTitle={`${Config.title} | Browser Support`}
        title={broswerSupport.title}
        copy={broswerSupport.copy}
      />
    )}
  </CopyContext.Consumer>
);

export default memo(BroswerSupport);
